import React, { SyntheticEvent, useState } from 'react';
import { LabelText, Label, ErrorText, HintText } from 'govuk-react';

import './Selectbox.css';

type SelectboxProps = {
  label?: string;
  // options: {
  //   name: string;
  //   value: string;
  // }[] | null;
  options: any;
  onChange?: (e: string) => void;
  className?: string;
  forwardRef?: React.LegacyRef<HTMLSelectElement>;
  id?: string;
  error?: boolean | null;
  errorText?: string;
  hint?: string;
  defaultValue?: string;
  hiddenLabel?: boolean;
  hideSelect?: boolean;
  disabled?: boolean;
}

export const Selectbox = ({ label, options, onChange, className, forwardRef, id, error = false, errorText, hint, defaultValue, hiddenLabel, hideSelect, disabled = false }: SelectboxProps) => {
  const [selected, setSelected] = useState<null | string>(null);

  const onSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(e.target.value);
    return onChange ? e.target.value !== 'Select' ? onChange(e.target.value) : onChange("Select") : null;
  }

  const classNames = `select-box ${className}${error ? " select-box--error" : ""}`;

  return (
    <Label className={classNames} id={id}>
      {label && <LabelText className={hiddenLabel ? "select-box__hidden-label" : ""}>{label}</LabelText>}
      {error && <ErrorText>{errorText}</ErrorText>}
      {options && (
        <select ref={forwardRef} className="select-box__select" onChange={onSelected} defaultValue={defaultValue ? defaultValue : undefined} disabled={disabled}>
          {!hideSelect && <option>Select</option>}
          {options.map((option: any, i: number) => (
            <option key={`${option.value}-${i}`} value={option.value}>{option.name}</option>
          ))}
        </select>
      )}
      {hint && <HintText>{hint}</HintText>}
    </Label>
  )
}
