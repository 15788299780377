import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { LoadingBox } from 'govuk-react';

import { loginRequest, trecoreConfig } from '../Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { ApiEndpoint } from '../models/apiEndPoints';
// temporary
import { testWorkspaceCosts } from '../../services/CombineWorkspacesAndCosts';

import './CostFigures.css';
import { WorkspaceCostsType } from '../models/workspace';

type CostFiguresProps = {
  id: string
}

export const CostFigures = ({ id }: CostFiguresProps) => {
  console.log("cost figrueres id: ", id);
  const { instance, accounts } = useMsal();
  const [workspaceCosts, setWorkspaceCosts] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.WorskspaceCosts}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setWorkspaceCosts(response.workspace_costs_items);
        console.log("response.workspace_costs_items: ", response.workspace_costs_items);
        setLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setLoading(false);
      })
    });
  }, []);

  const costs: WorkspaceCostsType[] = workspaceCosts && workspaceCosts.filter((item: WorkspaceCostsType) => item.workspace_id === id);
  // const costs: any = testWorkspaceCosts && testWorkspaceCosts.filter((item: any) => item.workspace_id === id);

  return (
    <LoadingBox loading={loading}>
      {costs && costs.length > 0 && (
        <article className="cost-figures">
          {/* <div className="cost-figures__container">
            <p className="cost-figures__title">Credit limit</p>
            <p className="cost-figures__figure">{Math.round(costs[0].credit_limit)}</p>
          </div>
          <div className="cost-figures__container">
            <p className="cost-figures__title">Available credit</p>
            <p className="cost-figures__figure">{Math.round(costs[0].available_credit)}</p>
          </div> */}
          <div className="cost-figures__container">
            <p className="cost-figures__title">Credit usage percentage</p>
            <p className="cost-figures__figure">{Math.round(costs[0].credit_percentage_usage)}</p>
          </div>
        </article>
      )}
    </LoadingBox>
  )
}
