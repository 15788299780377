type VMProps = {
  trecoreData: string
}

const VM=(props: VMProps)=> {
  return (
    <div>
      <a href={props.trecoreData} target='_blank' className="govuk-button" data-module="govuk-button">Connect</a>
    </div>
  )
}

export default VM;
