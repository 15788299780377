export interface Resource {
  templateName: string,
  properties: Properties
}

export interface ResourceVM extends Resource {

}

export interface Properties {
  display_name: string,
  description: string,
  os_image: string
}

export enum VMPowerStates {
  Running = "VM running",
  Starting = "VM starting",
  Stopping = "VM stopping",
  Stopped = "VM stopped",
  Deallocating = "VM deallocating",
  Deallocated = "VM deallocated"
}

export type ResourceProperties = {
  display_name: string,
  description: string,
  os_image: string,
  tre_id: string,
  azure_resource_id: string,
  connection_uri: string,
  hostname: string,
  ip: number,
  vm_size?: string
}

export type ResourceVMType = {
  templateName: string,
  id: string,
  templateVersion: number,
  properties: ResourceProperties,
  isEnabled: boolean,
  resourceType: string,
  deploymentStatus: string,
  _etag: string,
  resourcePath: string,
  resourceVersion: number,
  user: {
    id: string,
    name: string,
    email: string,
    roles: [string],
    roleAssignments: []
  },
  updatedWhen: number,
  workspaceId: string,
  ownerId: string,
  parentWorkspaceServiceId: string,
  azureStatus: {
    powerState: string
  }
}