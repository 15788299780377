import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Spinner } from "govuk-react";
import styled from "styled-components";
import hexRgb from "hex-rgb";

import { Lede } from "../Lede";

type LoadingBoxWithMessageProps = {
  loading: boolean;
  message: string;
  children: React.ReactNode;
}

const spinnerClassName = 'icon-loading';

const StyledContainer = styled('div')({
  position: 'relative',
  paddingBottom: '2px',
  minHeight: '10px',
});

const Innerwrap = styled('div')<{
  timeIn: number;
  timeOut: number;
  backgroundColor: string;
  backgroundColorOpacity: number;
}>(({ timeIn, timeOut, backgroundColor, backgroundColorOpacity }) => ({
  position: 'absolute',
  height: '100%',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [`& .spinner-positioning`]: {
    margin: '0 auto 2rem',
  },
  [`& .${spinnerClassName}`]: {
    position: 'absolute',
    zIndex: 101,
    opacity: 1,
    display: 'block',
    height: '100vh',
    maxHeight: 'calc(50vh + 100px)',
    transition: `opacity ${timeIn}ms ease-in-out`,
    willChange: 'opacity',
  },
  '& .overlay': {
    zIndex: 100,
    transition: `background-color ${timeIn}ms ease-in-out`,
    willChange: 'background-color',
    backgroundColor: `rgba(
      ${hexRgb(backgroundColor).red},
      ${hexRgb(backgroundColor).blue},
      ${hexRgb(backgroundColor).green},
      ${backgroundColorOpacity})`,
  },
  '&.fade-enter': {
    '& .overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        0)`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '&.fade-enter-active': {
    '& .overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeIn}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeIn}ms`,
      transitionDelay: `${timeIn / 2}ms`,
    },
  },
  '&.fade-exit': {
    '& .overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        ${backgroundColorOpacity})`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 1,
      transitionDuration: `${timeOut}ms`,
    },
  },
  '&.fade-exit-active': {
    '& .overlay': {
      backgroundColor: `rgba(
        ${hexRgb(backgroundColor).red},
        ${hexRgb(backgroundColor).blue},
        ${hexRgb(backgroundColor).green},
        0)`,
      transitionDuration: `${timeOut}ms`,
    },
    [`& .${spinnerClassName}`]: {
      opacity: 0,
      transitionDuration: `${timeOut}ms`,
    },
  },
}));

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
  height: '100%',
  width: '100%',
});

export const LoadingBoxWithMessage = ({ loading, message, children, ...props }: LoadingBoxWithMessageProps) => {
  const nodeRef = useRef(null);
  return (
    <StyledContainer {...props}>
      {/* @ts-expect-error: Let's ignore a single compiler error like this unreachable code */}
      <CSSTransition
        nodeRef={nodeRef}
        in={loading}
        backgroundColorOpacity={0.85}
        backgroundColour="#fff"
        timeIn={800}
        timeOut={200}
        classNames="fade"
        unmountOnExit
      >
        <Innerwrap
          ref={nodeRef}
          backgroundColor="#fff"
          backgroundColorOpacity={0.85}
          timeIn={800}
          timeOut={200}
        >
          <div className={spinnerClassName}>
            <Spinner
              className="spinner-positioning"
              height="50px"
              width="50px"
            />
            <Lede>{message}</Lede>
          </div>
          <Overlay className="overlay" />
        </Innerwrap>
      </CSSTransition>
      {children}
    </StyledContainer>
  )
}
