import React, { useEffect, useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { Heading, LoadingBox } from "govuk-react";

import { AppRolesContext } from '../contexts/AppRolesContext';
import { WorkspaceRoleName, RoleName } from "../components/models/roleNames";
import { loginRequest, trecoreConfig, trecoreServicesConfig } from "../components/Core/authConfig";
import { CallApiWithToken, HttpMethod, ResultType } from "../components/Core/fetch";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { VMPowerStates } from '../components/models/resource';

import { Title } from "../components/ui/Title";
import { Subtitle } from "../components/ui/Subtitle";
import Pagination from '../components/ui/Pagination';
import { ButtonLink } from '../components/ui/ButtonLink';
import { SearchBox } from '../components/ui/SearchBox';
import { MessageCard } from '../components/Error/MessageCard';
// test cost data for DEV and TEST environments
import { devWorkspaceCosts, testWorkspaceCosts } from '../services/CombineWorkspacesAndCosts';

import './dashboard.css';
import { useAuthApiCall } from '../components/hooks/useAuthAPICall';
import { CheckOperations } from '../services/CheckOps';
import { Td, Th } from '../components/ui/GDS-components/Table';
import { capitalizeFirst } from '../components/hooks/useStringFunc';
import { Selectbox } from '../components/ui/Selectbox';
import { WorkspaceCostsType, WorkspaceType } from '../components/models/workspace';
import { ButtonWithSort } from '../components/ui/ButtonWithSort';
import { SharedServiceType, WorkspaceServiceType } from '../components/models/workspaceServices';

export const Dashboard = () => {
  const { instance, accounts } = useMsal();
  const user = useContext(AppRolesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [allWorkspaces, setAllWorkspaces] = useState<WorkspaceType[]>([]);
  const [workspaces, setWorkspaces] = useState<any>([]);
  const [canViewCosts, setCanViewCosts] = useState(user.roles?.includes(RoleName.TREAdmin) ? true : false);
  const [workspaceServices, setWorkspaceServices] = useState<WorkspaceServiceType[]>([]);
  const [workspaceCount, setWorkspaceCount] = useState('--');
  const [sharedServicesCount, setSharedServicesCount] = useState('--');
  const [activeVms, setActiveVms] = useState<null | []>(null);
  const [sort, setSort] = useState<string | null>();
  const [sortCUPAsc, setSortCUPAsc] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState<string | null>();
  const [currentlyDeploying, setCurrentlyDeploying] = useState(false);
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    setIsLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(response.accessToken, `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`, HttpMethod.Get, '')
      .then(response => {
        const activeWorkspaces = user.roles?.includes(RoleName.TREAdmin) ? response.workspaces : response.workspaces.filter((element: WorkspaceType) => element.isEnabled === true);
        setAllWorkspaces(activeWorkspaces);
        setWorkspaces(activeWorkspaces);
        setWorkspaceCount(activeWorkspaces.length);
        setIsLoading(false);
        activeWorkspaces && activeWorkspaces.length > 0 && getOperationsAndServices(activeWorkspaces);
      })
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.SharedServices}`,
        HttpMethod.Get,
        ''
      )
      .then(response => {
        setSharedServicesCount(response.sharedServices.filter((obj: SharedServiceType) => obj.isEnabled === true).length);
      })
      .catch((err: string) => {
        setError(err);
        setIsLoading(false);
      })
    }).catch((err: string) => {
      setError(err);
      setIsLoading(false);
    })
  }, [instance, accounts]);

  const getOperationsAndServices = (workspaces: WorkspaceType[]) => {
    return (
      getWorkspaceServices(workspaces),
      getWorkspaceOperations(workspaces)
    )
  }

  const getWorkspaceOperations = (workspaces: WorkspaceType[]) => {
    const getOperations = workspaces.map((workspace: WorkspaceType) => {
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: trecoreConfig.scopes
      }).then(async (response) => {
        return await CallApiWithToken(
          response.accessToken,
          `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.Operations}`,
          HttpMethod.Get,
          ''
        ).catch((err: string) => {
          console.log("error fetching operations: ", err)
        })
      })
    });

    let operations: any = [];

    const returnedPromises = Promise.all(getOperations);

    returnedPromises.then(body => {
      body.forEach((response: any) => {
        operations.push(CheckOperations(response.operations));
      })
      setCurrentlyDeploying(operations.includes(true))
    })
  }

  const getWorkspaceServices = (workspaces: WorkspaceType[]) => {
    const getServices = workspaces.map((workspace: WorkspaceType) => {
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        return await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.WorkspaceServices}`,
          HttpMethod.Get,
          ''
        )
      });
    })

    let collectWorkspaceServices: WorkspaceServiceType[] = [];

    const returnedPromises = Promise.all(getServices);

    returnedPromises.then(body => {
      body.forEach((response: any) => {
        response.workspaceServices.length > 0 && collectWorkspaceServices.push(response.workspaceServices);
      })
      setWorkspaceServices(collectWorkspaceServices.flat(Infinity));
      getActiveVMs(collectWorkspaceServices.flat(Infinity), workspaces);
    }).catch((err: string) => {
      setError(err);
      setIsLoading(false);
    })
  };

  const getActiveVMs = (wsServices: WorkspaceServiceType[], workspaces: WorkspaceType[]) => {
    const filterServices = wsServices.filter((item: WorkspaceServiceType) => item.templateName && item.templateName.includes("guacamole"));

    const getVms = filterServices.map((wsService: WorkspaceServiceType, i: number) => {
      const workspace = workspaces.filter((item: WorkspaceType) => item.id === wsService.workspaceId);
      
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspace[0].properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        return await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsService.workspaceId}/${ApiEndpoint.WorkspaceServices}/${wsService.id}/${ApiEndpoint.UserResources}`,
          HttpMethod.Get,
          ''
        )
      });
    })

    let Vms: any = [];

    const returnedPromises = Promise.all(getVms);

    returnedPromises.then(body => {
      body.forEach((response: any) => {
        const filteredVms = response.userResources.filter((item: any) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting);
        filteredVms && Vms.push(filteredVms);
      })
      setActiveVms(Vms.flat(Infinity));
    })
    .catch((err: string) => {
      setError(err);
      setIsLoading(false);
    })
  }

  const onSearch = (e: string, workspaces: WorkspaceType[] = allWorkspaces) => {
    const searchTerm = e.toLowerCase();
    let newIds = workspaces;
    let newNames = workspaces;

    const filteredByIds = newIds.filter((item: WorkspaceType) => item.id.toLowerCase().includes(searchTerm));
    const filteredByName = newNames.filter((item: WorkspaceType) => item.properties.display_name.toLowerCase().includes(searchTerm));

    const searchWorkspaces = filteredByIds.concat(filteredByName);

    const newWorkspaces: WorkspaceType[] = Array.from(new Set(searchWorkspaces));
    return newWorkspaces;
  }

  const onSort = (e: string, workspaces: WorkspaceType[] = allWorkspaces) => {
    const sortedCUPs = (selected: string) => {
      setSortCUPAsc(!sortCUPAsc)
      let sortedData = workspaces;

      sortCUPAsc ? (
        sortedData.sort((a: any, b: any) => a.costs[0]?.credit_percentage_usage - b.costs[0]?.credit_percentage_usage)
      ) : (
        sortedData.sort((a: any, b: any) => b.costs[0]?.credit_percentage_usage - a.costs[0]?.credit_percentage_usage)
      );
      return sortedData;
    }
    const sortedData = (selected: string) => {
      let sortedData = workspaces;
      
      sortedData.sort((a:any, b:any) => {
        const A = e === 'updatedWhen' ? a[selected] : a.properties.display_name?.toUpperCase();
        const B = e === 'updatedWhen' ? b[selected] : b.properties.display_name?.toUpperCase();

        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      });

      return sortedData;
    }
    
    return e === "credit_percentage_usage" ? sortedCUPs(e) : sortedData(e);
  }

  const sortData = (e: string) => {
    setSort(e);
    searchedTerm ? setWorkspaces(onSort(e, onSearch(searchedTerm))) : setWorkspaces([...onSort(e)]);
  }

  const searchData = (e: string) => {
    const searchTerm = e.toLowerCase();
    setSearchedTerm(searchTerm);
    sort ? setWorkspaces(onSearch(searchTerm, onSort(sort))) : setWorkspaces(onSearch(searchTerm));
  }

  const sortByOptions = [
    {
      name: "Alphabetical order",
      value: "display_name"
    },
    {
      name: "Recently updated",
      value: "updatedWhen"
    }
  ];

  return (
    <>
      {error ? (
        <MessageCard msgData={error} />
      ) : (
        <>
          <header>
            <Title>Home</Title>
            {(user.roles?.includes(RoleName.TREAdmin)) && (
              <Subtitle>Dashboard</Subtitle>
            )}
          </header>
          <LoadingBox loading={isLoading}>
            <section className="dashboard__section">
              <div className="dashboard__figures-box">
                <p className="dashboard__figure">{workspaceCount && workspaceCount} <span className="dashboard__figure-text">Workspaces</span></p>
                <p className="dashboard__figure">{activeVms ? activeVms.length : '--'} <span className="dashboard__figure-text">Active VMs</span></p>
                <p className="dashboard__figure">{sharedServicesCount && sharedServicesCount} <span className="dashboard__figure-text">Active shared services</span></p>
              </div>
              <Heading as="h3" size={36} className="dashboard__heading">My available workspaces</Heading>
              {(user.roles?.includes(RoleName.TREAdmin)) && (
                <>
                  {currentlyDeploying ? (
                    <p className="dashboard__text">A workspace is being deployed, you can create another workspace once this has completed</p>
                  ) : (
                    <ButtonLink to="/createworkspace">Create workspace</ButtonLink>
                  )}
                </>
              )}
              <Selectbox
                className="dashboard__sort"
                label="Sort by"
                options={sortByOptions}
                onChange={(e: string) => sortData(e)}
              />
              <SearchBox className="dashboard__search-box" placeholder="Search for workspace or ID" onSearch={(e) => searchData(e.target.value)} />
              {workspaces && workspaces.length > 0 && (
                <Workspaces
                  workspaces={workspaces}
                  workspaceServices={workspaceServices}
                  canViewCosts={canViewCosts}
                  setCanViewCosts={setCanViewCosts}
                  setAllWorkspaces={setAllWorkspaces}
                  sortData={sortData}
                />
              )}
            </section>
          </LoadingBox>
        </>
      )}
    </>
  )
}

type WorkspacesProps = {
  workspaces: WorkspaceType[],
  workspaceServices: any,
  canViewCosts: boolean,
  setCanViewCosts: React.Dispatch<React.SetStateAction<boolean>>,
  setAllWorkspaces: React.Dispatch<React.SetStateAction<WorkspaceType[]>>,
  sortData: (e: string) => void
}

const Workspaces = ({ workspaces, workspaceServices, canViewCosts, setCanViewCosts, setAllWorkspaces, sortData }: WorkspacesProps) => {
  const [data, setData] = useState<WorkspaceType[]>(workspaces);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [workspaceCosts, setWorkspaceCosts] = useState<any>();
  const [combinedData, setCombinedData] = useState<null | WorkspaceType[]>();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    canViewCosts && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: trecoreConfig.scopes
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.WorskspaceCosts}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setWorkspaceCosts(response.workspace_costs_items);
        }).catch((err: string) => {
          console.log("error: ", err);
        })
      })
    );
  }, [canViewCosts]);

  const combineData = () => {
    let combinedArray: WorkspaceType[] = [];
    canViewCosts && workspaceCosts && workspaces && workspaces.map((workspace: WorkspaceType) => {
      const combined = Object.assign({}, workspace, {costs: workspaceCosts.filter((item: WorkspaceCostsType) => item.workspace_id === workspace.id)});
      combinedArray.push(combined);
    })
    return (setCombinedData(combinedArray), setAllWorkspaces(combinedArray));
  }

  useEffect(() => {
    workspaceCosts ? combineData() : setCombinedData(workspaces);
  }, [workspaces, workspaceCosts])

  useEffect(() => {
    combinedData && setData(combinedData!.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [workspaces, combinedData, currentPage]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = workspaces && Math.ceil(workspaces.length / recordsPerPage);

  return (
    <>
      <table className="dashboard__table">
        <thead>
          <tr>
            <Th>Workspace name</Th>
            <Th>Description</Th>
            {canViewCosts && (
              <Th>
                <ButtonWithSort onClick={() => sortData("credit_percentage_usage")}>CUP</ButtonWithSort>
              </Th>
            )}
            <Th>Status</Th>
            <Th>Deployment status</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((workspace: WorkspaceType) => {
            return (
              <Workspace key={workspace.id} workspace={workspace} setCanViewCosts={setCanViewCosts} canViewCosts={canViewCosts} workspaceCosts={workspaceCosts} workspaceServices={workspaceServices} />
            )
          })}
        </tbody>
      </table>
      {nPages > 1 ? (
        <Pagination
          className="dashboard__pagination"
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
    </>
  )
}

type WorkspaceProps = {
  workspace: WorkspaceType,
  setCanViewCosts: React.Dispatch<React.SetStateAction<boolean>>,
  canViewCosts: boolean,
  workspaceCosts: WorkspaceCostsType,
  workspaceServices: WorkspaceServiceType[]
}

const Workspace = ({workspace, setCanViewCosts, canViewCosts, workspaceCosts, workspaceServices}: WorkspaceProps) => {
  const [loading, setLoading] = useState(true);
  const [workspaceRoles, setWorkspaceRoles] = useState<Array<string>>([]);
  const [workspaceViewCosts, setWorkspaceViewCosts] = useState(false);
  const apiCall = useAuthApiCall();

  const getRoles = async () => {
    await apiCall(`${ApiEndpoint.Workspaces}/${workspace.id}`, HttpMethod.Get, workspace.properties.scope_id, undefined, ResultType.JSON, (roles: Array<string>) => {
      setWorkspaceRoles(roles);
      roles && roles.includes(WorkspaceRoleName.WorkspaceOwner) && setCanViewCosts(true);
      roles && roles.includes(WorkspaceRoleName.WorkspaceOwner) && setWorkspaceViewCosts(true);
      setLoading(false);
    }, true);
  }

  useEffect(() => {
    getRoles();
  }, []);

  const getLink = (wsId: string) => {
    const service = workspaceServices.filter((item: WorkspaceServiceType) => item.workspaceId === wsId && item.templateName === "tre-service-guacamole");

    return service.length > 0 ? `/workspaces/${wsId}/workspace-services/${service[0].id}/user-resources` : "";
  }

  const isResearcher = !loading && workspaceRoles && workspaceRoles.length === 1 && workspaceRoles[0] === WorkspaceRoleName.WorkspaceResearcher;

  const link = isResearcher ? getLink(workspace.id) : `/workspaces/${workspace.id}`;

  return (
    <tr key={workspace.id} className="govuk-table__row">
      {!loading && (
        <>
          <Td>
            <Link className="dashboard__workspace-link" to={link}>
              <p className="dashboard__workspace-title">{workspace.properties.display_name}</p>
            </Link>
          </Td>
          <Td>
            {workspace.properties.description}
          </Td>
          {canViewCosts && (
            <Td>{workspaceViewCosts && (workspace.costs && workspace.costs.length > 0) ? Math.round(workspace.costs[0].credit_percentage_usage) : ""}</Td>
          )}
          <Td>
            {workspace.isEnabled ? "Active": "Inactive"}
          </Td>
          <Td>
            {capitalizeFirst(workspace.deploymentStatus)}
          </Td>
        </>
      )}
    </tr>
  )
}
