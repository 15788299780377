import React from "react";
import { useMsal } from "@azure/msal-react";
import { TextButton } from "../ui/TextButton";

import './SignOutButton.css';

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();    
  
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    else if (logoutType === "direct") {
      instance.logout({
        postLogoutRedirectUri: "/",                
      });
    }
  }

  return (
    <TextButton className="log-out__button" onClick={() => handleLogout("redirect")}>
      Log out
    </TextButton>
  )
}
