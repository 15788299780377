import React from "react";

import { Th } from "./GDS-components/Table";
import { ButtonWithSort } from "./ButtonWithSort";

import "./TableHead.css";

type TableHeadProps = {
  headers: Header[]
}

type Header = {
  header: string;
  colSpan?: number;
  cupFunc?: () => void;
}

export const TableHead = ({ headers }: TableHeadProps) => {
  return (
    <thead>
      <tr>
        {headers.map((header: Header, i: number) => {
          return header.header !== "CUP" ? (
            <Th key={`${header.header}-${i}`} colspan={header.colSpan}>{header.header}</Th>
          ) : (
            <Th key={`${header.header}-${i}`} colspan={header.colSpan}>
              {header.cupFunc && <ButtonWithSort onClick={header.cupFunc}>{header.header}</ButtonWithSort>}
            </Th>
          )
        })}
      </tr>
    </thead>
  )
}
