import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Tag } from "govuk-react";
import { useMsal } from '@azure/msal-react';

import { loginRequest, trecoreConfig, trecoreServicesConfig } from '../../Core/authConfig';
import { AppRolesContext } from '../../../contexts/AppRolesContext';
import { useInterval } from '../useInterval';
import { WorkspaceRoleName, RoleName } from '../../models/roleNames';
import { ApiEndpoint } from '../../models/apiEndPoints';
import { CallApiWithToken, HttpMethod, ResultType } from '../../Core/fetch';
import { CheckOps } from '../../../services/CheckOps';
import { Td } from '../../ui/GDS-components/Table';
import { useAuthApiCall } from '../useAuthAPICall';

type useTableBodyProps = {
  sort: string | null | undefined;
  tableData: any;
  updatedWorkspace?: string | null;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  sortData: any;
  searchedTerm: string | null | undefined;
  onSearch: any;
  setWorkspaceUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableBody = ({
  tableData,
  sort,
  updatedWorkspace,
  setRefresh,
  sortData,
  searchedTerm,
  onSearch,
  setWorkspaceUpdating
}: useTableBodyProps) => {
  return (
    <tbody>
      {tableData && tableData.length > 0 && tableData.map((data:any) => {
        return (
          <TableRow
            key={data.id}
            data={data}
            updatedWorkspace={updatedWorkspace}
            setRefresh={setRefresh}
            sort={sort}
            sortData={sortData}
            searchedTerm={searchedTerm}
            onSearch={onSearch}
            setWorkspaceUpdating={setWorkspaceUpdating}
          />
        );
      })}
    </tbody>
  );
};

const TableRow = ({ data, updatedWorkspace, setRefresh, sort, sortData, searchedTerm, onSearch, setWorkspaceUpdating }: any) => {
  const { instance, accounts } = useMsal();
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${data.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(data, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    callOperations();
  }, []);

  useEffect(() => {
    searchedTerm && onSearch(searchedTerm);
    sort && sortData(sort);
  }, [updating]);

  return (
    <TableRowItem
      data={data}
      updating={updating}
      callOperations={callOperations}
      setWorkspaceUpdating={setWorkspaceUpdating}
    />
  )
}

export default TableBody;

const TableRowItem = ({ data, updating, callOperations, setWorkspaceUpdating }: any) => {
  const { instance, accounts } = useMsal();
  const appRolesCtx = useContext(AppRolesContext);
  const canViewCosts = (appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin));
  const [workspaceRoles, setWorkspaceRoles] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState<any>([]);
  const apiCall = useAuthApiCall();

  updating && setWorkspaceUpdating(true);

  useInterval(() => callOperations(), updating ? 10000 : null);

  const getServices = (workspaceId: string) => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${data.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}`,
        HttpMethod.Get,
        ''
      ).then((response) => {
        setServices(response.workspaceServices)
        setLoading(false);
      })
    })
  }

  const getRoles = async () => {
    await apiCall(`${ApiEndpoint.Workspaces}/${data.id}`, HttpMethod.Get, data.properties.scope_id, undefined, ResultType.JSON, (roles: Array<string>) => {
      console.log("roles: ", roles);
      setWorkspaceRoles(roles);
      roles.length === 1 && roles[0] === WorkspaceRoleName.WorkspaceResearcher ? getServices(data.id) : setLoading(false);
    }, true);
  }

  useEffect(() => {
    workspaceRoles.length === 0 && getRoles();
  }, []);

  const getLink = (wsId: string) => {
    const service = services ? services.filter((item: any) => item.workspaceId === wsId && item.templateName === "tre-service-guacamole") : [];

    return service.length > 0 ? `/workspaces/${wsId}/workspace-services/${service[0].id}/user-resources` : "";
  }

  const isResearcher = !loading && workspaceRoles.length === 1 && workspaceRoles[0] === WorkspaceRoleName.WorkspaceResearcher;

  const link = isResearcher ? getLink(data.id) : `/workspaces/${data.id}`;

  return (
    <>
      {loading ? (
        <p>loading...</p>
      ) : (
        <Table.Row key={data.id}>
          <Td>
            {updating || data.deploymentStatus === "deployment_failed" ? (
              data.properties.display_name
            ) : (
              <Link to={link} state={{ data: data.id }}>{data.properties.display_name}</Link>
            )}
          </Td>
          <Td>
            {appRolesCtx.roles?.includes(RoleName.TREAdmin) ? (
              data.id
            ) : (
              data.properties.description
            )}
          </Td>
          {canViewCosts && (
            <Td>
              {data.costs.length > 0 && Math.trunc(data.costs[0].credit_percentage_usage)}
            </Td>
          )}
          <Td>
            {updating ? (
              <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
            ) : (
              <Tag backgroundColor={data.isEnabled ? '#bfe3e0' : '#f6d6d2'} color={data.isEnabled ? '#10403c' : '#942514'}>{data.isEnabled ? "active" : "inactive"}</Tag>
            )}
          </Td>
          <Td>
            {updating ? (
              <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
            ) : (
              <Tag backgroundColor="#eeefef" color="#383f43">{data.deploymentStatus}</Tag>
            )}
          </Td>
        </Table.Row>
      )}
    </>
  )
}
