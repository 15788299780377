import React, { useState, useRef, useEffect, useContext } from "react";
import { WarningText } from "govuk-react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { ErrorSummary, LabelText, Input, Button, HintText, ErrorText } from "govuk-react";

import { Title } from "../components/ui/Title";
import { FormWrapper } from "../components/ui/FormWrapper";

// import { OnlineStatus } from "../services/OnlineStatus";
import { error } from "../types";
import { FormLabel } from "../components/ui/FormLabel";
import { FormButtonContainer } from "../components/ui/FormButtonContainer";
import { CancelButton } from "../components/ui/CancelButton";
import { graphConfig, loginRequest, trecoreConfig } from "../components/Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { Selectbox } from "../components/ui/Selectbox";
import { Textarea } from "../components/ui/Textarea";

import './Support.css';
import { NewSupportRequest } from "../components/models/support";
import { NotificationBox } from "../components/ui/NotificationBox";
import { IsOnlineContext } from "../contexts/IsOnlineContext";

export const Support = () => {
  const [errors, setErrors] = useState<null | error[]>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [workspaces, setWorkspaces] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [requestSuccessful, setRequestSuccessful] = useState<null | string>(null);
  const [apiError, setApiError] = useState(null);
  const isOnlineCtx = useContext(IsOnlineContext);
  const formRef = useRef<HTMLFormElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const secondaryEmailInputRef = useRef<HTMLInputElement>(null);
  const workspaceInputRef = useRef<HTMLInputElement>(null);
  const issueTypeInputRef = useRef<HTMLInputElement>(null);
  const errorMessageInputRef = useRef<HTMLInputElement>(null);
  const issueDescriptionInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // get workspaces
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setWorkspaces(response.workspaces);
        setLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setLoading(false);
      })
    })
    // get profile
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        graphConfig.graphMeEndpoint,
        HttpMethod.Get,
        ''
      ).then(async (response) => {
        setName(`${response.givenName} ${response.surname}`);
        setEmail(response.mail);
        setLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setLoading(false);
      })
    })
  }, []);

  const resetForm = () => {
    formRef.current?.reset();
  }

  const makeApiCall = (payload: any) => {
    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      scopes: trecoreConfig.scopes,
      account: accounts[0]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Support}`,
        HttpMethod.Post,
        payload,
        "*"
      ).then(response => {
        // set success status here
        console.log("response: ", response);
        setRequestSuccessful("Your message has been sent");
        document.getElementById("support-page")?.scrollIntoView();
        resetForm();
        setLoading(false);
      }).catch((err: any) => {
        // set error status here
        console.log("err: ", err);
        setApiError(err.message);
        setLoading(false);
      })
    })
  }

  const createPayload = (e: any) => {
    e.preventDefault();

    const enteredName = nameInputRef.current!.value;
    const enteredEmail = emailInputRef.current!.value;
    const enteredSecondaryEmail = secondaryEmailInputRef.current!.value;
    const workspace = workspaceInputRef.current ? workspaceInputRef.current!.value : null;
    const issueType = issueTypeInputRef.current!.value;
    const errorMessage = errorMessageInputRef.current!.value;
    const issueDescription = issueDescriptionInputRef.current!.value;

    let properties: NewSupportRequest["properties"] = {};

    properties = {
      recipients: process.env.REACT_APP_SUPPORT_RECIPIENT,
      secondary_recipients: enteredSecondaryEmail,
      name: enteredName,
      email: enteredEmail,
      subject: `CPRD-Safe: ${issueType}`,
      workspace: workspace,
      issue_type: issueType,
      error_message: errorMessage,
      issue_description: issueDescription
    }

    const addProperties = () => {
      if (enteredSecondaryEmail.length > 0) {
        return properties.secondaryEmail = enteredSecondaryEmail;
      }
    }

    addProperties();

    const newSupportRequest : NewSupportRequest = properties;
    makeApiCall(newSupportRequest);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const routeChange = () => {
    navigate('/homeLink/');
  }

  const onSubmit = (event: any) => {
    event.preventDefault();
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setErrors(null);
    const newErrors = [];

    const secondaryEmail = secondaryEmailInputRef.current?.value.trim();
    const trimmedEmail = secondaryEmail && secondaryEmail.replace(/,\s*$/, "");
    const emails = trimmedEmail && trimmedEmail.split(",");
    const invalidEmails = [];

    for (var i = 0; emails && i < emails.length; i++) {
      if (emails[i].trim() === "" || !emails[i].trim().match(emailRegEx)) {
        invalidEmails.push(emails[i]);
      }
    }

    if (secondaryEmail && invalidEmails.length > 0) {
      newErrors.push({
        targetName: "secondary-email",
        text: "Enter valid email address"
      })
    }

    if (secondaryEmail && secondaryEmail.length > 100) {
      newErrors.push({
        targetName: "secondary-email-length",
        text: "Enter less than 100 characters"
      })
    }

    const workspace = workspaceInputRef.current?.value;
    if (workspace === "Select") {
      newErrors.push({
        targetName: "workspace",
        text: "Select a workspace"
      })
    }

    const issueType = issueTypeInputRef.current?.value;
    if (issueType === "Select") {
      newErrors.push({
        targetName: "issue-type",
        text: "Select a type of issue"
      })
    }

    const errorMessage = errorMessageInputRef.current?.value;
    if (errorMessage && (errorMessage.length < 10 || errorMessage.length > 400)) {
      newErrors.push({
        targetName: "error-message",
        text: "Enter a valid error message"
      })
    }

    const issue = issueDescriptionInputRef.current?.value;
    if (!issue || (issue.length < 10 || issue.length > 200)) {
      newErrors.push({
        targetName: "issue-description",
        text: "Enter a valid issue description"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("support-page")?.scrollIntoView()
    ) : createPayload(event);
  }

  const getWorkspaces = (workspaces: any) => {
    const newWorkspaces = workspaces.map((workspace: any) => {
      let obj = {
        name: "",
        value: ""
      }
      obj.name = `${workspace.properties.display_name} - ${workspace.id}`;
      obj.value = `${workspace.properties.display_name} - ${workspace.id}`;
      return obj;
    })

    return newWorkspaces.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  return (
    <section id="support-page">
      {(apiError || requestSuccessful) && (
        <NotificationBox
          id="notification"
          error={apiError}
          text={requestSuccessful}
        />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <Title>Support</Title>
      <div className="support__header">
        {!loading && (
          <WarningText>CPRD Safe support team cannot support training or How To questions that are covered in the training section of the website. Please refer to {isOnlineCtx.isOnline ? <a href="https://www.cprd.com/cprd-safe-our-trusted-research-environment" target="_blank">CPRD Safe training and guidance</a> : "https://www.cprd.com/cprd-safe-our-trusted-research-environment outside CPRD Safe"} on the CPRD website.</WarningText>
        )}
        <p className="support__copy">For help with errors or bugs please use the contact form below.</p>
      </div>
      <form ref={formRef} onSubmit={onSubmit}>
        <FormWrapper>
          {name && email && (
            <>
              <FormLabel>
                <LabelText htmlFor="name">Name</LabelText>
                <Input id="name" ref={nameInputRef} defaultValue={name} readOnly />
              </FormLabel>
              <FormLabel>
                <LabelText htmlFor="email">Email</LabelText>
                <Input id="email" ref={emailInputRef} defaultValue={email} readOnly />
              </FormLabel>
            </>
          )}

          <FormLabel error={hasError("secondary-email") || hasError("secondary-email-length")} id="secondary-email secondary-email-length">
            <LabelText htmlFor="secondary-email">Secondary email</LabelText>
            <HintText>Enter a secondary email address (optional), if more than one separate them by a comma</HintText>
            {hasError("secondary-email") && <ErrorText>Enter a valid email address</ErrorText>}
            {hasError("secondary-email-length") && <ErrorText>Enter less than 100 characters</ErrorText>}
            <Input ref={secondaryEmailInputRef} />
          </FormLabel>

          {workspaces && (
            <Selectbox
              id="workspace"
              className="support__select"
              label="Workspace"
              forwardRef={workspaceInputRef}
              error={hasError("workspace")}
              hint="Select the workspace you require support with"
              errorText="Select a workspace"
              options={getWorkspaces(workspaces)}
            />
          )}

          <Selectbox
            id="issue-type"
            className="support__select"
            label="Type of issue"
            forwardRef={issueTypeInputRef}
            error={hasError("issue-type")}
            hint="Select the type of issue you're having"
            errorText="Select a type of issue"
            options={[
              {
                name: "Access to datacut",
                value: "Datacut-access"
              },
              {
                name: "Access to shared workspace",
                value: "Shared-workspace-access"
              },
              {
                name: "Access to VM",
                value: "Vm-access"
              },
              {
                name: "Airlock",
                value: "Airlock"
              },
              {
                name: "Costs - allowance usage",
                value: "Allowance-usage"
              },
              {
                name: "Error in training materials (doesn't match usage)",
                value: "Training-materials-error"
              },
              {
                name: "Error message",
                value: "Error-message"
              },
              {
                name: "Error with datacut - not-working",
                value: "Datacut-error-not-working"
              },
              {
                name: "Error with datacut - wrong data",
                value: "Datacut-error-wrong-data"
              },
              {
                name: "Error with tool provided",
                value: "Error-with-tool-provided"
              },
              {
                name: "License",
                value: "License"
              }
            ]}
          />

          <FormLabel error={hasError("error-message")} id="error-message">
            <LabelText>Error message</LabelText>
            <HintText>If you received an error message, copy and paste it here (optional)</HintText>
            {hasError("error-message") && <ErrorText>Enter the error message you received (between 10 and 400 characters)</ErrorText>}
            <Textarea forwardRef={errorMessageInputRef} />
          </FormLabel>

          <FormLabel error={hasError("issue-description")} id="issue-description">
            <LabelText>Issue description</LabelText>
            <HintText>Describe what you did before the problem occurred</HintText>
            {hasError("issue-description") && <ErrorText>Enter what you did before the problem occurred (between 10 and 200 characters)</ErrorText>}
            <Textarea forwardRef={issueDescriptionInputRef} />
          </FormLabel>

          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Submit request</Button>
            <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </section>
  )
}
