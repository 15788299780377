import React from 'react';
import { Button } from 'govuk-react';
import { useParams, useNavigate } from 'react-router-dom';

import { Title } from '../../../ui/Title';

import './index.css';

export const AirlockExportTriageRejection = () => {
  const navigate = useNavigate();
  const location = useParams();
  const wsId = location.id;

  const goToAirlockDashboard = () => {
    return navigate(`/workspaces/${wsId}/airlocks/`)
  }

  return (
    <>
      <Title>Export request rejected</Title>
      <p className="airlock-export-triage-rejection__copy">Your export request has been rejected as it did not meet CPRD disclosure rules. Refer to Safe output guidance by navigating to www.cprd.com/safe outside CPRD Safe for more information on how assessments are made.</p>
      <Button className="airlock-export-triage-rejection__button govuk-button" onClick={() => goToAirlockDashboard()}>Back to airlock dashboard</Button>
    </>
  )
}
