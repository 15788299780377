import { useEffect, useState } from "react";
import { Heading, PhaseBanner } from "govuk-react";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";

import './HeaderBar.css';
import { useMsal } from "@azure/msal-react";
import { graphConfig, loginRequest } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";

import { SignOutButton } from "../Login/SignOutButton";
import { UserType } from "../models/user";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const HeaderBar = () => {
  const { instance, accounts } = useMsal();
  const [userData, setUserData] = useState<null | UserType>(null);
  const [companyName, setCompanyName] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const userAuth = useIsAuthenticated();

  useEffect(() => {
    setIsLoading(true);
    userAuth && instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    }).then(async (res: any) => {
      await CallApiWithToken(
        res.accessToken,
        graphConfig.graphMeEndpoint,
        HttpMethod.Get,
        ''
      ).then(async (response) => {
        setUserData(response)
        await CallApiWithToken(
          res.accessToken,
          `${graphConfig.graphUsersEndpoint}/${response.id}?$select=companyName`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setCompanyName(response.companyName);
          setIsLoading(false);
        })
        .catch(err => console.log("err: ", err));
      })
      .catch(err => console.log("err: ", err));
    })
  }, [userAuth]);

  return (
    <>
      <header id="govuk-header-mhra" data-module="header" role="banner">
        <div className="govuk-header__container govuk-width govuk-width-container">
          <div className="govuk-header__wrapper">
            <a href="https://www.gov.uk/government/organisations/medicines-and-healthcare-products-regulatory-agency" title="MHRA" id="mhra-logo-link" className="external-no-style">
              <img className="header-bar__mhra-logo" src={`${process.env.REACT_APP_UI_REDIRECT_URL}assets/images/MHRA_Lesser_Arms_Landscape_WHITE.png`} height="34" alt="MHRA logo" title="MHRA logo" typeof="foaf:Image"/>
            </a>
            <AuthenticatedTemplate>
              <SignOutButton />
            </AuthenticatedTemplate>
          </div>
        </div>
      </header>
      <header className="govuk-header" data-module="header" role="banner">
        <div className="govuk-header__container govuk-width-container">
          <div className="region region-header">
            <div id="block-cprd-branding" className="block block-system block-system-branding-block">
              <a href="https://cprd.com/home" title="Home" rel="home" className="site-logo external-no-style">
                <img src={`${process.env.REACT_APP_UI_REDIRECT_URL}assets/images/cprdlogo.svg`} alt="Clinical Practice Research Datalink" typeof="foaf:Image"/>
              </a>
              <div className="tag-line">
                UK data driving real-world evidence
              </div>
            </div>
            <AuthenticatedTemplate>
              {!isLoading && (
                <>
                  {userData && (userData.givenName && userData.surname) && <Heading className="HeaderBar__heading HeaderBar__heading--name" as="p" size={36}>{`${userData.givenName} ${userData.surname}`}</Heading>}
                  {companyName && <Heading className="HeaderBar__heading HeaderBar__heading--company" as="p" size={24}>{companyName}</Heading>}
                </>
              )}
            </AuthenticatedTemplate>
          </div>
        </div>
      </header>
      <div className="HeaderBar__phase-wrapper">
        <PhaseBanner level="Beta">
          CPRD Safe
        </PhaseBanner>
      </div>
    </>
  );
};
