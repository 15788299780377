import React, { SetStateAction } from "react";
import { Operation, completedStates, inProgressStates } from "../components/models/operations";

export const CheckOps = (resource: any, operations: Operation[], setUpdating: React.Dispatch<SetStateAction<boolean>>, setRefresh: React.Dispatch<SetStateAction<boolean>>, updating: boolean) => {
  // We need to get latest operations once an action has been triggered......
  // Have a userUpdated flag that will trigger useEffect again so it will get updated operations
  if (resource && operations) {
    let resourceOps = operations.filter((o: Operation) => o.resourceId === resource.id);
    console.log("reourceOps: ", resourceOps);
    if (resourceOps && resourceOps.length > 0) {
      let latestOp = resourceOps[resourceOps.length - 1];
      console.log("latestOp: ", latestOp);
      if (inProgressStates.includes(latestOp.status)) {
        setUpdating(true);
        console.log("CheckOps inProgressStates");
      }
      if (completedStates.includes(latestOp.status) && updating) {
        setUpdating(false);
        setRefresh(true);
        console.log("CheckOps completedStates");
      }
    }
  }
}

export const CheckOperations = (operations: Operation[]) => {
  console.log("operations in func: ", operations);
  if (operations) {
    if (operations.length > 0) {
      let latestOp = operations[operations.length - 1];
      console.log("laterstOp: ", latestOp);
      if (inProgressStates.includes(latestOp.status)) {
        console.log("CheckOperations inProgessStates");
        return true
      }
      if (completedStates.includes(latestOp.status)) {
        console.log("CheckOperations completedStates");
        return false;
      }
    }
  }
}

export const CheckSingleOps = (operations: Operation[], setUpdating: React.Dispatch<SetStateAction<boolean>>, setRefresh: React.Dispatch<SetStateAction<boolean>>, updating: boolean) => {
  // We need to get latest operations once an action has been triggered......
  // Have a userUpdated flag that will trigger useEffect again so it will get updated operations
  if (operations) {
    if (operations.length > 0) {
      let latestOp = operations[operations.length - 1];
      console.log("latestOp: ", latestOp);
      if (inProgressStates.includes(latestOp.status)) {
        setUpdating(true);
        console.log("CheckSingleOps inProgressStates");
      }
      if (completedStates.includes(latestOp.status) && updating) {
        setUpdating(false);
        setRefresh(true);
        console.log("CheckSingleOps completedStates");
      }
    }
  }
}
