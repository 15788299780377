import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, Button, ErrorSummary, ErrorText, HintText, Heading, WarningText } from 'govuk-react';

import { error } from '../../../../types';
import { loginRequest, trecoreServicesConfig } from '../../../Core/authConfig';
import { WorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../../../Core/fetch';
import { ApiEndpoint } from '../../../models/apiEndPoints';
import { FormWrapper } from '../../../ui/FormWrapper';
import { Checkbox } from '../../../ui/Checkbox';
import { Fieldset } from '../../../ui/Fieldset';
import { RadioButton } from '../../../ui/RadioButton';

import { MessageCard } from '../../../Error/MessageCard';
import './index.css';
import { Lede } from '../../../ui/Lede';
import { AirlockTriage } from '../../../models/airlock';
import { IsOnlineContext } from '../../../../contexts/IsOnlineContext';

export const AirlockExportTriageStageTwo = () => {
  const [codeLists, setCodeLists] = useState<null | boolean>(null);
  const [statisticalTests, setStatisticalTests] = useState(false);
  const [statisticalTestsConfirmation, setStatisticalTestsConfirmation] = useState(false);
  const [coefficientsAssociation, setCoefficientsAssociation] = useState(false);
  const [coefficientsAssociationResidualDegrees, setCoefficientsAssociationResidualDegrees] = useState(false);
  const [coefficientsAssociationModelNotSaturated, setCoefficientsAssociationModelNotSaturated] = useState(false);
  const [coefficientsAssociationRegressionNotIncluded, setCoefficientsAssociationRegressionNotIncluded] = useState(false);
  const [shape, setShape] = useState(false);
  const [shapeStandardDeviations, setShapeStandardDeviations] = useState(false);
  const [shapeMinFive, setShapeMinFive] = useState(false);
  const [mode, setMode] = useState(false);
  const [modeConfirmation, setModeConfirmation] = useState(false);
  const [ratios, setRatios] = useState(false);
  const [ratiosConfirmationNRatios, setRatiosConfirmationNRatios] = useState(false);
  const [ratiosConfirmationHRatios, setRatiosConfirmationHRatios] = useState(false);
  const [giniCoefficients, setGiniCoefficients] = useState(false);
  const [giniCoefficientsConfirmationN, setGiniCoefficientsConfirmationN] = useState(false);
  const [giniCoefficientsConfirmationLessThan, setGiniCoefficientsConfirmationLessThan] = useState(false);
  const [frequencies, setFrequencies] = useState(false);
  const [frequenciesSmallFrequenciesSuppressed, setFrequenciesSmallFrequenciesSuppressed] = useState(false);
  const [frequenciesZerosFullCells, setFrequenciesZerosFullCells] = useState(false);
  const [frequenciesUnderlyingValuesIndependent, setFrequenciesUnderlyingValuesIndependent] = useState(false);
  const [frequenciesCategoriesComprehensiveData, setFrequenciesCategoriesComprehensiveData] = useState(false);
  const [position, setPosition] = useState(false);
  const [positionConfirmation, setPositionConfirmation] = useState(false);
  const [extremeValues, setExtremeValues] = useState(false);
  const [extremeValuesConfirmation, setExtremeValuesConfirmation] = useState(false);
  const [linearAggregates, setLinearAggregates] = useState(false);
  const [linearAggregatesDerivedGroups, setLinearAggregatesDerivedGroups] = useState(false);
  const [linearAggregatesPRatioDominanceRule, setLinearAggregatesPRatioDominanceRule] = useState(false);
  const [linearAggregatesNKDominanceRule, setLinearAggregatesNKDominanceRule] = useState(false);
  const [oddsRatios, setOddsRatios] = useState(false);
  const [oddsRatiosConfirmation, setOddsRatiosConfirmation] = useState(false);
  const [hazardSurvivalTables, setHazardSurvivalTables] = useState(false);
  const [hazardSurvivalTablesNumberPatientsSurvived, setHazardSurvivalTablesNumberPatientsSurvived] = useState(false);
  const [hazardSurvivalTablesExitDatesRelatives, setHazardSurvivalTablesExitDatesRelatives] = useState(false);
  const [hazardSurvivalTablesNoDatesWithSingleExit, setHazardSurvivalTablesNoDatesWithSingleExit] = useState(false);
  const [isAcroUsedPosition, setIsAcroUsedPosition] = useState<null | boolean>(null);
  const [isAcroUsedLinearAggregates, setIsAcroUsedLinearAggregates] = useState<null | boolean>(null);
  const [other, setOther] = useState(false);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState(false);
  const [elementToScrollTo, setElementToScrollTo] = useState<null | HTMLElement>(null);
  const [showSafeStatistics, setShowSafeStatistics] = useState(false);
  const [showUnsafeStatistics, setShowUnsafeStatistics] = useState(false);
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const params = useParams();
  const wsId = params.id;
  const arId = params.aid;
  const location = useLocation();
  const state: any = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    elementToScrollTo && elementToScrollTo?.scrollIntoView({block: "start", behavior: "smooth"})
  }, [elementToScrollTo])

  const createPayload = (e: any) => {
    e.preventDefault();

    const payload: any = {
      "codeLists": codeLists!.toString(),
      "statisticalTests": statisticalTests!.toString(),
      "statisticalTestsConfirmation": statisticalTestsConfirmation!.toString(),
      "coefficientsAssociation": coefficientsAssociation!.toString(),
      "coefficientsAssociationResidualDegrees": coefficientsAssociationResidualDegrees!.toString(),
      "coefficientsAssociationModelNotSaturated": coefficientsAssociationModelNotSaturated!.toString(),
      "coefficientsAssociationRegressionNotIncluded": coefficientsAssociationRegressionNotIncluded!.toString(),
      "shape": shape!.toString(),
      "shapeStandardDeviations": shapeStandardDeviations!.toString(),
      "shapeMinFive": shapeMinFive!.toString(),
      "mode": mode!.toString(),
      "modeConfirmation": modeConfirmation!.toString(),
      "ratios": ratios!.toString(),
      "ratiosConfirmationNRatios": ratiosConfirmationNRatios!.toString(),
      "ratiosConfirmationHRatios": ratiosConfirmationHRatios!.toString(),
      "giniCoefficients": giniCoefficients!.toString(),
      "giniCoefficientsConfirmationN": giniCoefficientsConfirmationN!.toString(),
      "giniCoefficientsConfirmationLessThan": giniCoefficientsConfirmationLessThan!.toString(),
      "frequencies": frequencies!.toString(),
      "frequenciesSmallFrequenciesSuppressed": frequenciesSmallFrequenciesSuppressed!.toString(),
      "frequenciesZerosFullCells": frequenciesZerosFullCells!.toString(),
      "frequenciesUnderlyingValuesIndependent": frequenciesUnderlyingValuesIndependent!.toString(),
      "frequenciesCategoriesComprehensiveData": frequenciesCategoriesComprehensiveData!.toString(),
      "position": position!.toString(),
      "positionConfirmation": positionConfirmation!.toString(),
      "extremeValues": extremeValues!.toString(),
      "extremeValuesConfirmation": extremeValuesConfirmation!.toString(),
      "linearAggregates": linearAggregates!.toString(),
      "linearAggregatesDerivedGroups": linearAggregatesDerivedGroups!.toString(),
      "linearAggregatesPRatioDominanceRule": linearAggregatesPRatioDominanceRule!.toString(),
      "linearAggregatesNKDominanceRule": linearAggregatesNKDominanceRule!.toString(),
      "oddsRatios": oddsRatios!.toString(),
      "oddsRatiosConfirmation": oddsRatiosConfirmation!.toString(),
      "hazardSurvivalTables": hazardSurvivalTables!.toString(),
      "hazardSurvivalTablesNumberPatientsSurvived": hazardSurvivalTablesNumberPatientsSurvived!.toString(),
      "hazardSurvivalTablesExitDatesRelatives": hazardSurvivalTablesExitDatesRelatives!.toString(),
      "hazardSurvivalTablesNoDatesWithSingleExit": hazardSurvivalTablesNoDatesWithSingleExit!.toString(),
      "isAcroUsedPosition": isAcroUsedPosition === null ? "False" : isAcroUsedPosition!.toString(),
      "isAcroUsedLinearAggregates": isAcroUsedLinearAggregates === null ? "False" : isAcroUsedLinearAggregates!.toString(),
      "other": other!.toString()
    }

    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (initialResponse) => {
      await CallApiWithToken(
        initialResponse.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockStatistics}`,
        HttpMethod.Post,
        payload
      ).then(async (re) => {
        await CallApiWithToken(
          initialResponse.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockExitStatistics}`,
          HttpMethod.Post,
          ''
        ).then(async(response) => {
          console.log("response for next stage: ", response.airlockRequest);
          const noAcro = "Many thanks, your answers indicate your requested outputs meet CPRD disclosure control rules. Please note that, as you have not used ACRO tools, your outputs will take longer for us to check.";
          const acro = "Many thanks, your answers indicate your requested outputs meet CPRD disclosure control rules. Your outputs will now be passed to an Output Checker for review.";

          // location logic 
          if (response.airlockRequest.triageLevel === AirlockTriage.TriageRejection || response.airlockRequest.triageLevel === AirlockTriage.Rejected) {
            navigate(`/workspaces/${wsId}/airlocks/airlock-export-triage-rejection/${arId}`);
          } else if (response.airlockRequest.triageLevel === AirlockTriage.Exemption) {
            navigate(`/workspaces/${wsId}/airlocks/airlock-export-triage-exemption/${arId}`, {state: { selectedFile: state.selectedFile }});
          } else {
            await CallApiWithToken(
              initialResponse.accessToken,
              `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockSubmit}`,
              HttpMethod.Post,
              null
            ).then (res => {
              if (response.airlockRequest.triageLevel === AirlockTriage.NoAcro) {
                navigate(`/workspaces/${wsId}/airlocks#exports`, { state: { requestComplete: true, requestMessage: noAcro, aID: arId } });
              } else if (response.airlockRequest.triageLevel === AirlockTriage.Acro) {
                navigate(`/workspaces/${wsId}/airlocks#exports`, { state: { requestComplete: true, requestMessage: acro, aID: arId } });
              } else {
                navigate(`/workspaces/${wsId}/airlocks#exports`, { state: { requestComplete: true, aID: arId } });
              }
              setLoading(false);
            }).catch((err: any) => {
              console.log("error: ", err);
              setErrorData(false);
              setLoading(false);
            })
          }
          setLoading(false);
        })
      }).catch((err: any) => {
        console.log("error: ", err);
        setErrorData(false);
        setLoading(false);
      })
    }).catch((err: any) => {
      console.log("error: ", err);
      setErrorData(err);
      setLoading(false);
    })
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrors(null);
    const newErrors = [];

    if (codeLists === null) {
      newErrors.push({
        targetName: "codeLists",
        text: "Select Yes or No"
      })
    }

    if (linearAggregates && isAcroUsedLinearAggregates === null) {
      newErrors.push({
        targetName: "isAcroUsedLinearAggregates",
        text: "Select Yes or No"
      })
    }

    if (position && isAcroUsedPosition === null) {
      newErrors.push({
        targetName: "isAcroUsedPosition",
        text: "Select Yes or No"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("error-boundary")?.scrollIntoView({block: "start", behavior: "smooth"})
    ) : (
      createPayload(e)
    )
  }

  const onCheckUnsafeStatistics = (check: boolean) => {
    setShowUnsafeStatistics(check);
    return !check && (
      setFrequencies(false),
      setFrequenciesSmallFrequenciesSuppressed(false),
      setFrequenciesZerosFullCells(false),
      setFrequenciesUnderlyingValuesIndependent(false),
      setFrequenciesCategoriesComprehensiveData(false),
      setPosition(false),
      setPositionConfirmation(false),
      setIsAcroUsedPosition(null),
      setExtremeValues(false),
      setExtremeValuesConfirmation(false),
      setLinearAggregates(false),
      setLinearAggregatesDerivedGroups(false),
      setLinearAggregatesPRatioDominanceRule(false),
      setLinearAggregatesNKDominanceRule(false),
      setIsAcroUsedLinearAggregates(null),
      setOddsRatios(false),
      setOddsRatiosConfirmation(false),
      setHazardSurvivalTables(false),
      setHazardSurvivalTablesNumberPatientsSurvived(false),
      setHazardSurvivalTablesExitDatesRelatives(false),
      setHazardSurvivalTablesNoDatesWithSingleExit(false)
    )
  }

  const onCheckSafeStatistics = (check: boolean) => {
    setShowSafeStatistics(check);
    return !check && (
      setStatisticalTests(false),
      setStatisticalTestsConfirmation(false),
      setCoefficientsAssociation(false),
      setCoefficientsAssociationResidualDegrees(false),
      setCoefficientsAssociationModelNotSaturated(false),
      setCoefficientsAssociationRegressionNotIncluded(false),
      setShape(false),
      setShapeStandardDeviations(false),
      setShapeMinFive(false),
      setMode(false),
      setModeConfirmation(false),
      setRatios(false),
      setRatiosConfirmationNRatios(false),
      setRatiosConfirmationHRatios(false),
      setGiniCoefficients(false),
      setGiniCoefficientsConfirmationN(false),
      setGiniCoefficientsConfirmationLessThan(false)
    )
  }

  return (
    <LoadingBox loading={loading}>
      {isOnlineCtx.isOnline ? (
        <Lede>Export function is only available in your CPRD Safe workspace</Lede>
      ) : (
        <>
          {errorData && (
            <MessageCard msgData={errorData} />
          )}
          <Heading as="h1" size={27}>Workspace name: {workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name}</Heading>
          <Heading as="h1" size="SMALL">New export request 3/3</Heading>
          <Heading as="h2" size={36}>Statistics information</Heading>
          <p className="airlock-export-triage-stage-two__copy">Where possible, submit safe and unsafe statistics as separate requests for efficient review</p>
          <div id="error-boundary">
            {errors && errors.length > 0 && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem submitting your request"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView({block: "start", behavior: "smooth"})}
              />
            )}
          </div>
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <Fieldset id="codeLists" error={hasError("codeLists")}>
                <legend>
                  <p className="airlock-export-triage-stage-two__legend">The output only contains code lists or programming code</p>
                </legend>
                {hasError("codeLists") && <ErrorText>Make a selection</ErrorText>}
                <div className="airlock-export-triage-stage-two__radio-wrapper">
                  <RadioButton
                    label="Yes"
                    name="codeLists-yes"
                    checked={codeLists === true}
                    onChange={() => setCodeLists(true)}
                  />
                  <RadioButton
                    label="No"
                    name="codeLists-no"
                    checked={codeLists === false}
                    onChange={() => setCodeLists(false)}
                  />
                </div>
              </Fieldset>
              <Fieldset>
                <legend>Select all that apply</legend>
                <Checkbox
                  label="Safe statistics"
                  onChange={() => onCheckSafeStatistics(!showSafeStatistics)}
                />
                <Checkbox
                  label="Unsafe statistics"
                  onChange={() => onCheckUnsafeStatistics(!showUnsafeStatistics)}
                />
                {showSafeStatistics && showUnsafeStatistics && (
                  <WarningText>Inclusion of both safe and unsafe statistics in your export request may lead to a longer review time for your request</WarningText>
                )}
              </Fieldset>
              {showSafeStatistics && (
                <Fieldset id="statisticalTests" error={hasError("statisticalTests")}>
                  <legend>
                    <Heading size={36}>Safe statistics</Heading>
                  </legend>
                  <HintText>Select all that apply</HintText>
                  <Checkbox
                    label="Statistical hypothesis tests (e.g., t-test, chi-square, R-square, standard errors)"
                    onChange={(e: any) => (setStatisticalTests(e.target.checked), setElementToScrollTo(document.getElementById("statistical-tests")))}
                    id="statistical-tests"
                  />
                  {statisticalTests && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item" id="statisticalTestsConfirmation">
                      <Checkbox
                        label="Please confirm that your tests were run on a minimum of five patients."
                        onChange={(e: any) => setStatisticalTestsConfirmation(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Coefficients of association (e.g., estimated coefficients, models, AN(C)OVA, correlation tables, density plots, kernel density plots)"
                    onChange={(e: any) => (setCoefficientsAssociation(e.target.checked), setElementToScrollTo(document.getElementById("coefficients-association")))}
                    id="coefficients-association"
                  />
                  {coefficientsAssociation && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item" id="coefficientsAssociationResidualDegrees" error={hasError("coefficientsAssociationResidualDegrees")}>
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include coefficients of association. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The residual degrees of freedom (number of observations less number of variables) exceeds five"
                        onChange={(e: any) => setCoefficientsAssociationResidualDegrees(e.target.checked)}
                      />
                      <Checkbox
                        label="The model is not saturated (i.e., not all variables are categorical and fully interacted)"
                        onChange={(e: any) => setCoefficientsAssociationModelNotSaturated(e.target.checked)}
                      />
                      <Checkbox
                        label="Your outputs do not include a regression with a single binary explanatory variable"
                        onChange={(e: any) => setCoefficientsAssociationRegressionNotIncluded(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Shape (e.g., standard deviation, skewness, kurtosis)"
                    onChange={(e: any) => (setShape(e.target.checked), setElementToScrollTo(document.getElementById("shape-type")))}
                    id="shape-type"
                  />
                  {shape && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include statistics of shape. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="Any standard deviations are greater than zero"
                        onChange={(e: any) => setShapeStandardDeviations(e.target.checked)}
                      />
                      <Checkbox
                        label="All statistics of shape were calculated for a minimum of five patients or GP practices"
                        onChange={(e: any) => setShapeMinFive(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Mode"
                    onChange={(e: any) => (setMode(e.target.checked), setElementToScrollTo(document.getElementById("mode-type")))}
                    id="mode-type"
                  />
                  {mode && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include modes. Please confirm that the mode is not the only value (i.e., that different observations have different values)"
                        onChange={(e: any) => setModeConfirmation(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Non-linear concentration ratios (e.g., Herfindahl-Hirchsmann index, diversity index)"
                    onChange={(e: any) => (setRatios(e.target.checked), setElementToScrollTo(document.getElementById("ratio-type")))}
                    id="ratio-type"
                  />
                  {ratios && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include non-linear concentration ratios. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="N>2"
                        onChange={(e: any) => setRatiosConfirmationNRatios(e.target.checked)}
                      />
                      <Checkbox
                        label="H<0.81"
                        onChange={(e: any) => setRatiosConfirmationHRatios(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Gini coefficients or Lorenz curves"
                    onChange={(e: any) => (setGiniCoefficients(e.target.checked), setElementToScrollTo(document.getElementById("gini-coefficients")))}
                    id="gini-coefficients"
                  />
                  {giniCoefficients && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include Gini coefficients or Lorenz curves. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="N>2"
                        onChange={(e: any) => setGiniCoefficientsConfirmationN(e.target.checked)}
                      />
                      <Checkbox
                        label="The coefficient is less than 100%"
                        onChange={(e: any) => setGiniCoefficientsConfirmationLessThan(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                </Fieldset>
              )}
              {showUnsafeStatistics && (
                <Fieldset id="unsafe-statistics">
                  <legend>
                    <Heading size={36}>Unsafe statistics</Heading>
                  </legend>
                  <HintText>Select all that apply</HintText>
                  <Checkbox
                    label="Frequencies (e.g., frequency tables, histograms, shares, alluvial flow graphs, heat maps, line graphs, pie charts, scatter graphs, scatter plots, smoothed histograms, waterfall charts)"
                    onChange={(e: any) => (setFrequencies(e.target.checked), setElementToScrollTo(document.getElementById("frequency-type")))}
                    id="frequency-type"
                  />
                  {frequencies && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include frequencies. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="All counts <5 and frequencies derived from groups containing <5 patients or GP practices have been suppressed"
                        onChange={(e: any) => setFrequenciesSmallFrequenciesSuppressed(e.target.checked)}
                      />
                      <Checkbox
                        label="All zeroes and full cells (100%) are evidential or structural (i.e., something you would expect)"
                        onChange={(e: any) => setFrequenciesZerosFullCells(e.target.checked)}
                      />
                      <Checkbox
                        label="Underlying values are genuinely independent (i.e., they do not come from the same patient, the patients do not all have the same family number and do not all come from the same GP practice)"
                        onChange={(e: any) => setFrequenciesUnderlyingValuesIndependent(e.target.checked)}
                      />
                      <Checkbox
                        label="The categories are comprehensive and apply to all data (i.e., all categories of each categorical variable are presented)."
                        onChange={(e: any) => setFrequenciesCategoriesComprehensiveData(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Position (e.g., median, percentiles, box plots)"
                    onChange={(e: any) => (setPosition(e.target.checked), setElementToScrollTo(document.getElementById("position-type")))}
                    id="position-type"
                  />
                  {position && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include statistics of position. Please confirm the numbers for each group (and complementary groups) are ≥5"
                        onChange={(e: any) => setPositionConfirmation(e.target.checked)}
                      />
                      <Fieldset id="isAcroUsedPosition" error={hasError("isAcroUsedPosition")}>
                        <legend>
                          <p className="airlock-export-triage-stage-two__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                        </legend>
                        {hasError("isAcroUsedPosition") && <ErrorText>Make a selection</ErrorText>}
                        <div className="airlock-export-triage-stage-two__radio-wrapper">
                          <RadioButton
                            label="Yes"
                            name="isAcroUsedPosition-yes"
                            checked={isAcroUsedPosition === true}
                            onChange={() => setIsAcroUsedPosition(true)}
                          />
                          <RadioButton
                            label="No"
                            name="isAcroUsedPosition-no"
                            checked={isAcroUsedPosition === false}
                            onChange={() => setIsAcroUsedPosition(false)}
                          />
                        </div>
                      </Fieldset>
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Extreme values (e.g., maxima, minima)"
                    onChange={(e: any) => (setExtremeValues(e.target.checked), setElementToScrollTo(document.getElementById("extreme-values")))}
                    id="extreme-values"
                  />
                  {extremeValues && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include extreme values. Please confirm the maximum or minimum presented are non-informative and structural."
                        onChange={(e: any) => setExtremeValuesConfirmation(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Linear aggregates (e.g., means, totals, simple indexes, linear correlation ratios, bar graphs, mean plots)"
                    onChange={(e: any) => (setLinearAggregates(e.target.checked), setElementToScrollTo(document.getElementById("linear-aggregates")))}
                    id="linear-aggregates"
                  />
                  {linearAggregates && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include linear aggregates. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The linear aggregates have been derived from groups containing ≥5 patients or GP practices."
                        onChange={(e: any) => setLinearAggregatesDerivedGroups(e.target.checked)}
                      />
                      <Checkbox
                        label="The P-ratio dominance rule has been calculated and is greater than 10%. (NB: ACRO will check this automatically)."
                        onChange={(e: any) => setLinearAggregatesPRatioDominanceRule(e.target.checked)}
                      />
                      <Checkbox
                        label="The N-K dominance rule has been calculated for the 2 largest values and is less than 90%. (NB: ACRO will check this automatically)."
                        onChange={(e: any) => setLinearAggregatesNKDominanceRule(e.target.checked)}
                      />
                      {/* Make error messaging conditional based on position being selected first */}
                      <Fieldset id="isAcroUsedLinearAggregates" error={hasError("isAcroUsedLinearAggregates")}>
                        <legend>
                          <p className="airlock-export-triage-stage-two__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                        </legend>
                        {hasError("isAcroUsedLinearAggregates") && <ErrorText>Make a selection</ErrorText>}
                        <div className="airlock-export-triage-stage-two__radio-wrapper">
                          <RadioButton
                            label="Yes"
                            name="isAcroUsedLinearAggregates-yes"
                            checked={isAcroUsedLinearAggregates === true}
                            onChange={() => setIsAcroUsedLinearAggregates(true)}
                          />
                          <RadioButton
                            label="No"
                            name="isAcroUsedLinearAggregates-no"
                            checked={isAcroUsedLinearAggregates === false}
                            onChange={() => setIsAcroUsedLinearAggregates(false)}
                          />
                        </div>
                      </Fieldset>
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Odds ratios, risk ratios or other proportionate risks"
                    onChange={(e: any) => (setOddsRatios(e.target.checked), setElementToScrollTo(document.getElementById("odd-ratios")))}
                    id="odd-ratios"
                  />
                  {oddsRatios && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include odds ratios, risk ratios or other proportionate risks. Please confirm the underlying contingency table has been produced and is included in the requested outputs"
                        onChange={(e: any) => setOddsRatiosConfirmation(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Hazard and survival tables (e.g., tables of survival/death rates, Kaplan-Meier graphs)"
                    onChange={(e: any) => (setHazardSurvivalTables(e.target.checked), setElementToScrollTo(document.getElementById("hazard-survival-tables")))}
                    id="hazard-survival-tables"
                  />
                  {hazardSurvivalTables && (
                    <Fieldset className="airlock-export-triage-stage-two__indented-item">
                      <legend>
                        <p className="airlock-export-triage-stage-two__legend">You stated that your requested outputs include hazard or survival tables. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The number of patients who survived is ≥5"
                        onChange={(e: any) => setHazardSurvivalTablesNumberPatientsSurvived(e.target.checked)}
                      />
                      <Checkbox
                        label="Exit dates are relative, not absolute"
                        onChange={(e: any) => setHazardSurvivalTablesExitDatesRelatives(e.target.checked)}
                      />
                      <Checkbox
                        label="There are no dates with a single exit"
                        onChange={(e: any) => setHazardSurvivalTablesNoDatesWithSingleExit(e.target.checked)}
                      />
                    </Fieldset>
                  )}
                </Fieldset>
              )}
              <Fieldset id="other">
                <legend>
                  <Heading size={36}>Other</Heading>
                </legend>
                <Checkbox
                  label="There are other statistics, or types of output, included"
                  onChange={(e: any) => setOther(e.target.checked)}
                />
              </Fieldset>
              <Button className="airlock-export-triage-stage-two__submit govuk-button" type="submit">Save and next</Button>
            </FormWrapper>
          </form>
        </>
      )}
    </LoadingBox>
  )
}
