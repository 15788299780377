import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, LoadingBox, SectionBreak, Button, WarningText } from 'govuk-react';
import { useMsal } from '@azure/msal-react';
import { loginRequest, trecoreServicesConfig } from '../Core/authConfig';
import { useAuthApiCall } from '../hooks/useAuthAPICall';

import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { ApiEndpoint } from '../models/apiEndPoints';
import { SummaryList } from '../ui/GDS-components/SummaryList';
import { BackLink } from '../ui/GDS-components/BackLink';
import { capitalizeFirst } from '../hooks/useStringFunc';
import { AirlockRequest, AirlockRequestStatus, AirlockRequestType } from '../models/airlock';

import { AppRolesContext } from '../../contexts/AppRolesContext';
import { IsOnlineContext } from '../../contexts/IsOnlineContext';
import { ExportTriageData } from './ExportTriage/Review';

import './AirlockAuditScreen.css';

export const AirlockAuditScreen = () => {
  const ws = useParams();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);

  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState<null | AirlockRequest>(null);

  useEffect(() => {
    setLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${ws.id}/${ApiEndpoint.AirlockRequests}/${ws.aid}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          console.log("response: ", response);
          setRequest(response.airlockRequest);
          setLoading(false);
        }).catch((err: any) => {
          console.log("error: ", err);
          setLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  const getStageOne = () => {
    const data = [
      {
        key: "Destination workspace",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Request name",
        value: request && request.title
      },
      {
        key: "Request justification",
        value: request && request.businessJustification
      },
      {
        key: "Name",
        value: request && request.files.length > 0 && request.files[0].name
      },
      {
        key: "Size",
        value: request && request.files.length > 0 && `${(request.files[0].size/(1024**2)).toFixed(2)} MB`
      }
    ];

    return data;
  }

  const getRequestorDetails = () => {
    const data = [
      {
        key: "Requestor",
        value: request && request.createdBy.name
      },
      {
        key: "Type",
        value: request && `${capitalizeFirst(request.type)} file`
      },
      {
        key: "Status",
        value: request && capitalizeFirst(request.status).replace("_", " ")
      },
      {
        key: "Workspace name",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Created date",
        value: request && new Date(request.createdWhen * 1000).toLocaleDateString("en-GB")
      },
      {
        key: "Reason for request",
        value: request && request.businessJustification
      },
      {
        key: "File details",
        value: ""
      },
      {
        key: "File name",
        value: request && request.files.length > 0 ? request.files[0]?.name : "-"
      },
      {
        key: "File size",
        value: request && request.files.length > 0 ? `${(request.files[0]?.size/(1024**2)).toFixed(2)} MB` : "-"
      }
    ];

    return data;
  }

  const getSubmittedDate = (request: AirlockRequest | null) => {
    const historyItem = request && request.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Submitted);
    return historyItem ? new Date(historyItem.updatedWhen * 1000).toLocaleDateString("en-GB") : null;
  }

  const getOverview = () => {
    const data = [
      {
        key: "Name",
        value: request && request.title
      },
      {
        key: "Airlock request ID",
        value: ws.aid
      },
      {
        key: "Workspace ID",
        value: ws.id
      },
      {
        key: "Workspace name",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Requestor",
        value: request && request.createdBy.name
      },
      {
        key: "Created date",
        value: request && new Date(request.createdWhen * 1000).toLocaleDateString("en-GB")
      },
      {
        key: "Submitted date",
        value: getSubmittedDate(request)
      },
      {
        key: "Reviewed date",
        value: request && request.reviews.length > 0 ? new Date(request.reviews[0].dateCreated * 1000).toLocaleDateString("en-GB") : "-"
      },
      {
        key: "Status",
        value: request && capitalizeFirst(request.status).replace("_", " ")
      },
      {
        key: "Reason for decision",
        value: request && request.reviews.length > 0 ? request.reviews[0].decisionExplanation : "-"
      },
      {
        key: "File name",
        value: request && request.files.length > 0 ? request.files[0]?.name : "-"
      },
      {
        key: "File size",
        value: request && request.files.length > 0 ? `${(request.files[0]?.size/(1024**2)).toFixed(2)} MB` : "-"
      }
    ];

    return data;
  }

  console.log("request: ", request);

  const backToLink = request && request.type === AirlockRequestType.Import ? `/workspaces/${ws.id}/airlocks#imports` : `/workspaces/${ws.id}/airlocks#exports`;

  return (
    <LoadingBox loading={loading}>
      {workspaceCtx.workspace.properties && request && (
        <>
          <BackLink to={backToLink}>Back to my requests</BackLink>
          <Heading as="h1" size="LARGE">{workspaceCtx.workspace.properties.display_name}</Heading>
          <Heading as="h2" size="MEDIUM">New {request.type} request</Heading>
          <SummaryList heading="Set up information" data={getStageOne()} greyHeader />
          <ExportTriageData request={request} />
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Requestor details" data={getRequestorDetails()} greyHeader />
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Overview of request" data={getOverview()} />
          <Download request={request} />
        </>
      )}
    </LoadingBox>
  )
}

type DownloadProps = {
  request: AirlockRequest;
}

const Download = ({ request }: DownloadProps) => {
  const [loading, setLoading] = useState(false);
  const [filesLink, setFilesLink] = useState<string>();
  const [filesLinkError, setFilesLinkError] = useState<null | boolean>(null);
  
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const apiCall = useAuthApiCall();
  const { id } = useParams();

  const requestId = request.createdBy.id;
  const userId = appRolesCtx.OId;

  useEffect(() => {
    (request.status !== AirlockRequestStatus.Rejected && request.status !== AirlockRequestStatus.Failed && request.status !== AirlockRequestStatus.BlockedByScan && request.status !== AirlockRequestStatus.InReview) && generateFilesLink();
  }, []);

  const generateFilesLink = useCallback(async () => {
    setLoading(true);
    try {
      let airlockSaSTokenurl = `${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}/${request.id}/${ApiEndpoint.AirlockLink}`;
      const linkObject = (await apiCall(airlockSaSTokenurl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));
      console.log("linkObject: ", linkObject);

      if (request.files.length > 0) {
        setFilesLink(linkObject.containerUrl.replace(request.id, `${request.id}/${request.files[0].name}`));
        setLoading(false);
      } else {
        setFilesLink(linkObject.containerUrl);
        setLoading(false);
      }
    } catch (err: any) {
      console.log("err: ", err);
      setFilesLinkError(true);
      setLoading(false);
    }
  }, [apiCall]);

  return (
    <>
      {loading ? (
        "loading..."
      ) : (
        <div className="airlock-audit-screen__download">
          {isOnlineCtx.isOnline ? (
            <>
              {request.type === AirlockRequestType.Import ? (
                null
              ) : (
                <>
                  {request.status === AirlockRequestStatus.Approved && filesLink ? (
                    <a
                      className="govuk-button"
                      href={filesLink}
                      target="_blank"
                      download={request.files.length > 0 ? request.files[0].name : "Download file"}
                    >
                      Download
                    </a>
                  ) : (
                    <>
                      <Button
                        className="govuk-button"
                        target="_blank"
                        disabled={true}
                        download={request.files.length > 0 ? request.files[0].name : "Download file"}
                      >
                        Download
                      </Button>
                      {request.status === AirlockRequestStatus.BlockedByScan && (
                        <WarningText>You must be inside CPRD Safe to download the file but this file was blocked by scan and is not available for download as it may be mailicious.</WarningText>
                      )}
                      {(request.status === AirlockRequestStatus.Rejected || request.status === AirlockRequestStatus.Failed) && (
                        <WarningText>You must be inside CPRD Safe to download the file but this file was {request.status} and is not available for download.</WarningText>
                      )}
                      {request.status === AirlockRequestStatus.InReview && (
                        <>
                          {requestId === userId && (
                            <WarningText>You must be inside CPRD Safe to download the file but this file is currently in review and will be available for download once reviewed.</WarningText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {filesLink && (
                <>
                  <a
                    className="govuk-button"
                    href={filesLink}
                    target="_blank"
                    download={request.files.length > 0 ? request.files[0].name : "Download file"}
                  >
                    Download
                  </a>
                </>
              )}
              {filesLinkError && "error downloading link"}
              {request.status === AirlockRequestStatus.BlockedByScan && (
                <WarningText>This file was blocked by scan and is not available for download as it may be mailicious.</WarningText>
              )}
              {(request.status === AirlockRequestStatus.Rejected || request.status === AirlockRequestStatus.Failed) && (
                <WarningText>This file was {request.status} and is not available for download.</WarningText>
              )}
              {request.status === AirlockRequestStatus.InReview && (
                <>
                  {requestId === userId && (
                    <WarningText>This file is currently in review and will be available for download once reviewed.</WarningText>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
