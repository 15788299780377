import React from 'react';
import { Button } from 'govuk-react';

type CancelButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

export const CancelButton = ({ children, className, onClick, disabled }: CancelButtonProps) => {
  return (
    <Button
      buttonColour="#f3f2f1"
      buttonShadowColour="#929191"
      buttonTextColour="#0b0c0c"
      onClick={onClick}
      className={className && className}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}
