import React from 'react';
import { Link } from 'react-router-dom';

import './TextLink.css';

type TextLinkProps = {
  children: React.ReactNode;
  to: string;
  state?: any;
}

export const TextLink = ({ children, to, state }: TextLinkProps) => {
  return (
    <Link className="text-link" to={to} state={state}>{children}</Link>
  )
}
