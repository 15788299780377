import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
//import "./styles/govuk-frontend-4.4.1.min.css";
//import "./styles/index.scss";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/Core/authConfig";
import { BrowserRouter } from "react-router-dom";
//import { CustomLog } from "../src/components/Core/logConfig"

//import $ from 'jquery';
//import Popper from 'popper.js'
import CookieConsent from "react-cookie-consent";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <App />            
            </MsalProvider>
            <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay>
                We use cookies on this site to enhance your user experience.
            </CookieConsent>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
