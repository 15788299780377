import React from "react";
import { WorkspaceType } from "../components/models/workspace";


export const WorkspaceContext = React.createContext({
  roles: [] as Array<string>,
  setRoles: (roles: Array<string>) => { },
  setWorkspace: (w: WorkspaceType) => { },
  workspace: {} as WorkspaceType,
  workspaceApplicationIdURI: "" as string
});
