import React from 'react';

import './Pagination.css';

const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  className = ""
}: {
  nPages: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  className?: string
}) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

  const nextPage = () => {
    if(currentPage !== nPages) setCurrentPage(currentPage + 1)
  }

  const prevPage = () => {
    if(currentPage !== 1) setCurrentPage(currentPage - 1)
  }

  const classNames = `govuk-pagination mhra-pagination ${className}`;

  return (
    <nav className={classNames} role="navigation" aria-label="results">
      <ul className='pagination justify-content-center'>
        {currentPage > 1 && (
          <li className="page-item">
          <div className="govuk-pagination__prev">
            <button
              className="govuk-link govuk-pagination__link" rel="prev" 
              onClick={prevPage} 
            >
              <svg className="govuk-pagination__icon govuk-pagination__icon--prev" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
                  <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>
              </svg>
              <span className="govuk-pagination__link-title">Previous</span>
            </button>
          </div>
        </li>
        )}
        {pageNumbers.map((pgNumber: number) => (
          <li key={pgNumber} className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >
            <button onClick={() => setCurrentPage(pgNumber)} className='page-link'>
              {pgNumber}
            </button>
          </li>
        ))}
        {currentPage !== nPages && (
          <li className="govuk-pagination__next">
            <button className="govuk-link govuk-pagination__link" rel="next" onClick={nextPage}> <span className="govuk-pagination__link-title">Next</span> 
              <svg className="govuk-pagination__icon govuk-pagination__icon--next" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
                <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>
              </svg>
            </button>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Pagination;
