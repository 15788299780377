import { CallApiWithToken, HttpMethod, ResultType } from "../components/Core/fetch";
import { useMsal } from "@azure/msal-react";
import { loginRequest, trecoreServicesConfig } from "../components/Core/authConfig";
import { useContext, useEffect, useRef, useState } from "react";
import { TRECoreWorkspaceServicesData } from "../components/workspaces/TRECoreWorkspaceServicesData";
import { useParams } from "react-router";
import {MessageCard} from "../components/Error/MessageCard";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { WorkspaceContext } from "../contexts/WorkspaceContext";

export const WorkspaceServices = (props:any) => {
  const workspaceCtx = useContext(WorkspaceContext);
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();    
  const [trecoreServiceData, settrecoreServiceData] = useState(null);
  const [errorData, setErrorData] = useState(null);
  // do I need this?
  const [updatedService, setUpdatedService] = useState<null | string>(null);
  const [refresh, setRefresh] = useState(false);

  let wsId = useParams();
  let finalurl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.WorkspaceServices}`;
  
  useEffect(() =>  {      
    const getWorkspace = async () => {
      if (workspaceCtx.workspace.properties !== undefined && workspaceCtx.workspace.id == wsId.id) {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
        }).then(async (response) => {
          await CallApiWithToken(response.accessToken, finalurl, HttpMethod.Get, '')
          .then(response => settrecoreServiceData(response))
          .catch((err:any)=> setErrorData(err));
            setIsLoading(false);
        }).catch((err:any) => {
          setErrorData(err);
          setIsLoading(false);
        });
      }
    };
    getWorkspace();
    refresh && setRefresh(false);
    refresh && setUpdatedService(null);
  }, [instance, accounts, finalurl, workspaceCtx, refresh]);

    
  {if (isLoading) {
      return (
        <section>
          <p>Loading...</p>
        </section>
      );
    }
  }

  return (
    <div>
      {trecoreServiceData ? (
        <TRECoreWorkspaceServicesData
          trecoreData={trecoreServiceData}
          updatedService={updatedService}
          setRefresh={setRefresh}
        />
      ) : (
        <MessageCard msgData={errorData} />
      )}
    </div>
  );
}
