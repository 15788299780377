import { ButtonWithSort } from "../../ui/ButtonWithSort";
import { Th } from "../../ui/GDS-components/Table";

type UseTableHeadProps = {
  columns: {
    label: string,
    accessor: string
  }[],
  cupFunc?: () => void
}

const useTableHead = ({ columns, cupFunc }: UseTableHeadProps) => {

  return (
    <thead>
      <tr>
        {columns.map(({ label, accessor }:{ label: string, accessor: string }) => {
          // const header = label === "CUP" ? 
          return label !== "CUP" ? (
            <Th key={accessor}>{label}</Th>
          ) : (
            <Th key={accessor}>
              {cupFunc && <ButtonWithSort onClick={cupFunc}>{label}</ButtonWithSort>}
            </Th>
          );
        })}
      </tr>
    </thead>
  );
};

export default useTableHead;
