import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { AppRolesContext } from "../../../contexts/AppRolesContext";
import Pagination from "../../ui/Pagination";
import { GetStatus } from "../../ui/GetTag";
import { Th, Td } from "../../ui/GDS-components/Table";
import { AirlockRequest, FullRequest } from "../../models/airlock";

/**
 * Renders information about the workspace obtained from TRE Core API
 * @param props 
 */

type AirlockAdminHistoryDataProps = {
  airlockListParam: {
    airlockRequests: FullRequest[];
  };
  searchByTitleParam: string;
}

 export const AirlockAdminHistoryData = ({airlockListParam, searchByTitleParam}: AirlockAdminHistoryDataProps) => {
  console.log("airlockListParam: ", airlockListParam);
  console.log("searchByTitleParam: ", searchByTitleParam);
  const appRolesCtx = useContext(AppRolesContext);    
  const hasPropsChanged = useHasChanged(airlockListParam)
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);    
  let newfilterData: any[] = [];

  airlockListParam.airlockRequests.map((element: FullRequest) =>  (
    element.airlockRequest.history.map((el: any) => {
    if(el.updatedBy.id === appRolesCtx.OId)
      newfilterData.push(element);
    })
  ))

  let searchResult: FullRequest | any[];

  if (searchByTitleParam!=='') {
    searchResult = newfilterData.filter(
      (posts: FullRequest) => {
        return posts.airlockRequest.title.toLowerCase().includes(searchByTitleParam)  
      }
    )
  } else {
    searchResult = newfilterData
  }

  //If props get changed then we need to reset current page.
  useEffect(() => {        
    if(hasPropsChanged) {        
      setCurrentPage(1)
    }
  }, [hasPropsChanged])
    
  //Paging
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = searchResult.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(searchResult.length / recordsPerPage)

  const AirlockDetailData = currentRecords.map((element: FullRequest) => (                            
    <tr className="govuk-table__row" key={element.airlockRequest.id}>
      <Th scope="row">{element.airlockRequest.title}</Th>
      <Td>{element.airlockRequest.id}</Td>
      <Td>{`${moment.unix(element.airlockRequest.createdWhen).format('DD/MM/YY')}`}</Td>
      <Td><p>{element.airlockRequest.type}</p></Td>
      <Td>
        <GetStatus status={element.airlockRequest.status} />
      </Td>            
    </tr>
  ));

  return (        
    <div>               
      <table>                
        <thead>
          <tr>
            <Th>Airlock Title</Th>
            <Th>Airlock Id</Th>
            <Th>Date Created</Th>
            <Th>Request Type</Th>
            <Th>Status</Th>
          </tr>
        </thead>
        <tbody>
          {AirlockDetailData}
        </tbody>
      </table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

const useHasChanged= (val: AirlockAdminHistoryDataProps["airlockListParam"]) => {
  const prevVal = usePrevious(val)
  return prevVal !== val
}

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
