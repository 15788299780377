import React, { useState, useEffect, useContext, SetStateAction } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, Tag, Button } from 'govuk-react';

import { loginRequest, trecoreServicesConfig } from '../components/Core/authConfig';
import { WorkspaceContext } from '../contexts/WorkspaceContext';

import { Modal } from '../components/ui/Modal';
import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { TableHead } from '../components/ui/TableHead';
import { TextButton } from '../components/ui/TextButton';
import { NotificationBox } from '../components/ui/NotificationBox';
import { useAuthApiCall } from '../components/hooks/useAuthAPICall';
import { CheckOps } from '../services/CheckOps';
import { useInterval } from '../components/hooks/useInterval';
import { MessageCard } from '../components/Error/MessageCard';
import { inProgressStates } from '../components/models/operations';
import { Td } from '../components/ui/GDS-components/Table';

import './ManageUserResources.css';
import { ResourceVMType } from '../components/models/resource';

export const ManageUserResources = () => {
  const params = useParams();
  const navigate = useNavigate();
  const workspaceCtx = useContext(WorkspaceContext);
  const apiCall = useAuthApiCall();
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userResources, setUserResources] = useState([]);
  const [updatedResource, setUpdatedResource] = useState<null | string>(null);
  const [pausedUserResource, setPausedUserResource] = useState(false);
  const [changeStateError, setChangeStateError] = useState(null);
  const [selectedUserResource, setSelectedUserResource] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const [deploying, setDeploying] = useState(false);
  const [confirmPauseDelete, setConfirmPauseDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    setLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${params.id}/${ApiEndpoint.WorkspaceServices}/${params.rid}/${ApiEndpoint.UserResources}`,
          HttpMethod.Get,
          null
        ).then(response => {
          const deploying = response.userResources.filter((item: any) => item.deploymentStatus.includes(inProgressStates));
          setDeploying(deploying.length > 0);
          setUserResources(response.userResources);
          setLoading(false);
        }).catch((err: any) => {
          setError(err)
          setLoading(false);
        })
      }).catch((err:any) => {
        setError(err);
        setLoading(false);
      })
    )
    pausedUserResource && setPausedUserResource(false);
    refresh && setRefresh(false);
    refresh && setUpdatedResource(null);
  }, [workspaceCtx, refresh, pausedUserResource]);

  const onPause = async (id: string, workspaceId: string, serviceId: string, etag: string, enable: boolean) => {
    const pause = {
      isEnabled: enable
    }
    setLoading(true);
    await apiCall(
      `/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}/${serviceId}/${ApiEndpoint.UserResources}/${id}`,
      HttpMethod.Patch,
      workspaceCtx.workspaceApplicationIdURI,
      pause,
      undefined,
      undefined,
      false,
      etag
    ).then(response => {
      setPausedUserResource(true);
      setLoading(false);
      setChangeStateError(null);
      setUpdatedResource(id);
    })
    .catch((err: any) => {
      setLoading(false);
      setChangeStateError(err.message);
    })
  }

  const onDelete = async (id: string, serviceId: string) => {
    setLoading(true);
    await apiCall(
      `/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${serviceId}/${ApiEndpoint.UserResources}/${id}`,
      HttpMethod.Delete,
      workspaceCtx.workspaceApplicationIdURI
    ).then(response => {
      setChangeStateError(null);
      setPausedUserResource(true);
      setLoading(false);
      setUpdatedResource(id);
    }).catch((err: any) => {
      setLoading(false);
      setChangeStateError(err.message);
    })
  }

  const notificationCopy = selectedAction === "delete" ? (
    `The following user resource is awaiting deletion: ${selectedUserResource?.properties?.display_name}`
  ) : (
    `The following user resource has been ${selectedAction}d: ${selectedUserResource?.properties?.display_name}`
  );

  const routeChange = () => {
    const path = `/manage-workspaces/${params.id}/manage-workspace-services/${params.rid}/createvm`;
    navigate(path);
  }

  return (
    <>
      {location.state && (!changeStateError || !selectedUserResource?.properties) && !confirmPauseDelete && (
        <NotificationBox
          error={null}
          text="The following workspace has been updated: "
          updated={location.state.updated}
        />
      )}
      {error ? (
        <MessageCard msgData={error} />
      ) : (
        <section>
          {(changeStateError || selectedUserResource?.properties) && confirmPauseDelete && (
            <NotificationBox
              error={changeStateError}
              text={notificationCopy}
            />
          )}
          <header>
            <Title>Manage user resources</Title>
            <Subtitle>List of user resources</Subtitle>
          </header>
          <div className="manage-user-resources__button-wrapper">
            {deploying ? (
              <p>Currently deploying a user resource, once it has finished you can create another</p>
            ) : (
              <Button className="govuk-button manage-user-resources__button" onClick={() => routeChange()}>Create a new VM</Button>
            )}
          </div>
          <LoadingBox loading={loading}>
            {userResources && userResources.length > 0 && (
              <UserResources
                onDelete={onDelete}
                onPause={onPause}
                selectedAction={selectedAction}
                selectedUserResource={selectedUserResource}
                setConfirmPauseDelete={setConfirmPauseDelete}
                setRefresh={setRefresh}
                setSelectedAction={setSelectedAction}
                setSelectedUserResource={setSelectedUserResource}
                updatedResource={updatedResource}
                userResources={userResources}
              />
            )}
          </LoadingBox>
        </section>
      )}
    </>
  );
};

type UserResourcesProps = {
  onDelete: (id: string, serviceId: string) => void,
  onPause: (id: string, workspaceId: string, serviceId: string, etag: string, enable: boolean) => void,
  selectedAction: null | string,
  selectedUserResource: ResourceVMType,
  setConfirmPauseDelete: React.Dispatch<SetStateAction<boolean>>,
  setRefresh: React.Dispatch<SetStateAction<boolean>>,
  setSelectedAction: React.Dispatch<SetStateAction<string | null>>,
  setSelectedUserResource: React.Dispatch<SetStateAction<ResourceVMType>>,
  updatedResource: string | null,
  userResources: ResourceVMType[]
}

const UserResources = ({
  onDelete,
  onPause,
  selectedAction,
  selectedUserResource,
  setConfirmPauseDelete,
  setRefresh,
  setSelectedAction,
  setSelectedUserResource,
  updatedResource,
  userResources
}: UserResourcesProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const tableHeaders = [
    {
      header: "Resource name"
    },
    {
      header: "Status",
      colSpan: 4
    }
  ];

  const openModal = (resource: ResourceVMType, action: string) => {
    setModalOpen(true);
    setSelectedUserResource(resource);
    setSelectedAction(action);
  }

  const confirmAction = () => {
    selectedAction === "delete" ? onDelete(selectedUserResource.id, selectedUserResource.parentWorkspaceServiceId) : onPause(selectedUserResource.id, selectedUserResource.workspaceId, selectedUserResource.parentWorkspaceServiceId, selectedUserResource._etag, !selectedUserResource.isEnabled);
    setModalOpen(false);
    setConfirmPauseDelete(true);
  }

  const cancelAction = () => {
    setConfirmPauseDelete(false);
    setModalOpen(false);
  }

  const actionCopy = selectedAction === "delete" ? (
    "You are about to delete this user resource, are you sure you want to delete this user resource permanently?"
  ) : (
    `You are about to ${selectedAction} this user resource temporarily, are you sure you want to ${selectedAction} this user resource?`
  );

  return (
    <>
      {modalOpen && (
        <Modal
          action={selectedAction}
          copy={actionCopy}
          onAction={() => confirmAction()}
          onExit={() => cancelAction()}
          title={`Are you sure you want to ${selectedAction} this user resource?`}
        />
      )}
      <table className="manage-user-resources__table">
        <TableHead headers={tableHeaders} />
        <tbody>
          {userResources.map((resource: ResourceVMType) => {
            return (
              <UserResource
                key={resource.id}
                openModal={openModal}
                resource={resource}
                setRefresh={setRefresh}
                updatedResource={updatedResource}
              />
            )
          })}
        </tbody>
      </table>
    </>
  )
}

type UserResourceProps = {
  openModal: (resource: ResourceVMType, action: string) => void,
  resource: ResourceVMType,
  setRefresh: React.Dispatch<SetStateAction<boolean>>,
  updatedResource: string | null
}

const UserResource = ({ openModal, resource, setRefresh, updatedResource }: UserResourceProps) => {
  const workspaceCtx = useContext(WorkspaceContext);
  const { instance, accounts } = useMsal();
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${resource.parentWorkspaceServiceId}/${ApiEndpoint.UserResources}/${resource.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(resource, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && callOperations();
  }, [workspaceCtx]);

  useEffect(() => {
    updatedResource === resource.id && callOperations();
  }, [updatedResource]);

  return (
    <UserResourceItem
      callOperations={callOperations}
      openModal={openModal}
      resource={resource}
      updating={updating}
    />
  )
}

type UserResourceItemProps = {
  callOperations: () => void,
  openModal: (resource: ResourceVMType, action: string) => void,
  resource: ResourceVMType,
  updating: boolean
}

const UserResourceItem = ({ callOperations, openModal, resource, updating }: UserResourceItemProps) => {
  const params = useParams();
  useInterval(() => callOperations(), updating ? 10000 : null);
  return (
    <tr>
      <Td>{resource.properties.display_name}</Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          resource.isEnabled ? <Tag tint="GREEN">Active</Tag> : <Tag tint="RED">Inactive</Tag>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          !resource.isEnabled && <TextButton onClick={() => openModal(resource, "delete")}>Delete</TextButton>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          <Link to={`/manage-workspaces/${params.id}/manage-workspace-services/${params.rid}/update-user-resource/${resource.id}`}>Update</Link>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          <TextButton onClick={() => openModal(resource, resource.isEnabled ? "pause" : "unpause")}>
            {resource.isEnabled ? "Pause" : "Unpause"}
          </TextButton>
        )}
      </Td>
    </tr>
  )
}
