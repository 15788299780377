import { useContext, useEffect, useRef, useState } from 'react';
import { ResourceVM, Properties } from '../models/resource';
import { error } from '../../types';
import { Button, LabelText, HintText, Input, ErrorText, ErrorSummary } from 'govuk-react';

import { FormWrapper } from '../ui/FormWrapper';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { FormLabel } from '../ui/FormLabel';
import { Textarea } from '../ui/Textarea';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { CharacterCount } from '../../services/CharacterCount';

{/*https://bobbyhadz.com/blog/react-unable-to-type-in-input */}
export const CreateVMForm = (props:any) => {
  const [errors, setErrors] = useState<null | error[]>(null);
  const [templateType, setTemplateType] = useState('');
  const [imageName, setImageName] = useState<any>("");
  const [vmNameCharactersRemaining, setVmNameCharactersRemaining] = useState<number>(50);
  const [descriptionCharactersRemaining, setDescriptionCharactersRemaining] = useState<number>(100);
  const workspaceCtx = useContext(WorkspaceContext);
  const vmNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);

  const checkTemplateType = (name: string) => {
    if (name === "tre-workspace-e-msl") {
      // exploratory
      setTemplateType("tre-service-guacamole-windowsvm-e-msl");
      setImageName(process.env.REACT_APP_VM_E_MSL_IMAGE_NAME);
    } else if (name === "tre-workspace-a-msl") {
      // analytical
      setTemplateType("tre-service-guacamole-windowsvm-a-msl");
      setImageName(process.env.REACT_APP_VM_A_MSL_IMAGE_NAME);
    } else if (name === "tre-workspace-rdg") {
      setTemplateType("tre-service-guacamole-windowsvm-rdg");
      setImageName(process.env.REACT_APP_VM_RDG_IMAGE_NAME);
    } else {
      setTemplateType("tre-service-guacamole-windowsvm-ssl");
      setImageName(process.env.REACT_APP_VM_SSL_IMAGE_NAME);
    }
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && (
      checkTemplateType(workspaceCtx.workspace.templateName)
    )
  }, [workspaceCtx]);

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const newLineRegex = /\r?\n/g;

  const createVMHandler = (event:any) => {
    event.preventDefault();
    setErrors(null);
    const newErrors = [];

    const inputRegex = /^[a-zA-Z0-9%&()\-_=+:';,.?\s]*$/;

    const entereddescription = descriptionInputRef.current!.value.replace(newLineRegex, " ");
    const enteredvmName = vmNameInputRef.current!.value.replace(newLineRegex, " ");

    if (!enteredvmName || (enteredvmName.length < 5 || enteredvmName.length > 50) || !enteredvmName.match(inputRegex)) {
      newErrors.push({
        targetName: "vmname",
        text: "Enter a valid VM name"
      })
    }

    if (entereddescription && (entereddescription.length < 10 || entereddescription.length > 100) || !entereddescription.match(inputRegex)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid VM description"
      })
    }
    
    const properties : Properties = {
      display_name: enteredvmName,
      description: entereddescription,
      os_image: imageName
    };

    const newResourceVMRequest : ResourceVM = {
      templateName: templateType,
      properties: properties
    };
    
    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : props.onAddImportRequest(newResourceVMRequest);        
  };

  return (
    <>
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={createVMHandler}>
        <FormWrapper>
          <FormLabel error={hasError("vmname")} id="vmname">
            <LabelText className="govuk-label govuk-label--4">VM name</LabelText>
            {hasError("vmname") && <ErrorText>{(vmNameInputRef.current!.value.length < 5 || vmNameInputRef.current!.value.length > 50) ? "Enter a valid VM name minimum of 5 characters and maximum of 50" : "Only the letters a to z, numbers 0 to 9, spaces and the characters %&()-_+=:;',.? are allowed"}</ErrorText>}
            <Input ref={vmNameInputRef} onChange={() => CharacterCount(vmNameInputRef.current!.value.replace(newLineRegex, " ").length, 50, setVmNameCharactersRemaining)} maxLength="50" />
            <p>{vmNameCharactersRemaining} character(s) remaining</p>
            <HintText>Minimum 5 characters, maximum 50 characters including spaces. Only letters a to z, numbers 0 to 9, spaces and the following characters are allowed: %&()-_+=:;',.?</HintText>
          </FormLabel>
            
          <FormLabel error={hasError("description")} id="description">
            <LabelText htmlFor="description">VM description</LabelText>
            {hasError("description") && <ErrorText>{(descriptionInputRef.current!.value.length < 10 || descriptionInputRef.current!.value.length > 100) ? "Enter a valid VM description minimum of 10 characters and maximum of 100" : "Only the letters a to z, numbers 0 to 9, spaces and the characters %&()-_+=:;',.? are allowed"}</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} maxLength={100} onChange={() => CharacterCount(descriptionInputRef.current!.value.replace(newLineRegex, " ").length, 100, setDescriptionCharactersRemaining)} />
            <p>{descriptionCharactersRemaining} character(s) remaining</p>
            <HintText>Minimum 10 characters, maximum 100 characters including spaces. Only letters a to z, numbers 0 to 9, spaces and the following characters are allowed: %&()-_+=:;',.?</HintText>
          </FormLabel>

          <p>When you submit your request you will be taken back to the List of VMs page. Your new VM may take up to 10 minutes to be created. The List of VMs page will update when this is completed.</p>

          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Submit</Button>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </>
  );
}
