import React from "react";

import './TextButton.css';

type TextButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
}

export const TextButton = ({ children, onClick, className }: TextButtonProps) => {
  const classNames = `text-button${className ? ` ${className}` : ""}`;
  return (
    <button className={classNames} onClick={onClick}>{children}</button>
  )
}
