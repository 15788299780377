import React from 'react';
import AriaModal from 'react-aria-modal';

import { CancelButton } from './CancelButton';
import { CloseIcon } from '../icons/CloseIcon';

import './Modal.css';

type ModalProps = {
  onExit: () => void;
  title: string;
  copy: string;
  action: string | null;
  onAction: () => void;
  header?: string;
  options?: any;
  warning?: any;
  disableButton?: boolean;
}

export const Modal = ({ action, copy, onAction, onExit, title, header = "Important", options, warning, disableButton }: ModalProps) => {
  return (
    <AriaModal
      dialogClass="workspace-modal"
      onExit={onExit}
      titleText="Are you sure you want to perform this action"
    >
      <header className="workspace-modal__header">
        <h4 className="workspace-modal__important">{header}</h4>
        <button className="workspace-modal__close" onClick={onExit}>
          <CloseIcon className="workspace-modal__close-icon" />
        </button>
      </header>
      <section className="workspace-modal__body">
        <h4 className="workspace-modal__title">{title}</h4>
        <p className="workspace-modal__copy">{copy}</p>
        {options && options}
        {warning && warning}
        <footer className="workspace-modal__footer">
          <button className="govuk-button workspace-modal__action" onClick={onAction} disabled={disableButton}>{action}</button>
          <CancelButton className="workspace-modal__cancel" onClick={onExit} disabled={disableButton}>Cancel</CancelButton>
        </footer>
      </section>
    </AriaModal>
  )
}
