
import { LogLevel } from "@azure/msal-browser";


export const msalConfig = {
    auth: {        
        clientId: process.env.REACT_APP_CLIENT_ID!, 
        authority: process.env.REACT_APP_AUTHORITY!,        
        //redirectUri: "https://tre-ui.azurewebsites.net/"
        //redirectUri: "https://tre-ui-dev.azurewebsites.net/"
        //redirectUri: "https://tre-ui-test.azurewebsites.net/"
        //redirectUri: "https://tre-ui-prod.azurewebsites.net/"        
        //redirectUri: "http://localhost:44321/"
        //redirectUri: "https://tre.dev.cprd.com/"
        redirectUri: process.env.REACT_APP_UI_REDIRECT_URL! 

    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii: any) => {	
                if (containsPii) {		
                    return;		
                }
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;	
                }	
            }	
        }	
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["User.Read"],
    prompt: "select_account"
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users",
    graphEndpoint: "https://graph.microsoft.com/v1.0",
    graphGroups: "https://graph.microsoft.com/beta/groups",
    graphGroupWorkspaceResearchers: "Workspace Researchers",
    graphGroupWorkspaceOwners: "Workspace Owners"
};

export const trecoreConfig = {
    trecoreEndpoint: process.env.REACT_APP_TRE_CORE_API_ENDPOINT!,
    scopes: [`${process.env.REACT_APP_TRE_CORE_API_WORKSPACE_SCOPES}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
};


export const trecoreServicesConfig = {
    trecoreEndpoint: process.env.REACT_APP_TRE_CORE_API_ENDPOINT!,
    scopes: [`${process.env.REACT_APP_TRE_CORE_API_WORKSPACE_SERVICES_SCOPES}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
};


