import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox } from 'govuk-react';

import { graphConfig, loginRequest } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod, ResultType } from '../components/Core/fetch';
import { WorkspaceContext } from '../contexts/WorkspaceContext';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { TableHead } from '../components/ui/TableHead';
import { TextButton } from '../components/ui/TextButton';
import { SearchBox } from '../components/ui/SearchBox';
import { ButtonLink } from '../components/ui/ButtonLink';
import { MessageCard } from '../components/Error/MessageCard';
import { Lede } from '../components/ui/Lede';
import { CostFigures } from '../components/ui/CostFigures';
import { Td } from '../components/ui/GDS-components/Table';

import './ManageWorkspace.css';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { useAuthApiCall } from '../components/hooks/useAuthAPICall';
import { WorkspaceRoleName } from '../components/models/roleNames';
import { WorkspaceGraphUser, WorkspaceType } from '../components/models/workspace';

export const ManageWorkspace = () => {
  const { instance, accounts } = useMsal();
  const [users, setUsers] = useState<[] | WorkspaceGraphUser[]>([]);
  const [allUsers, setAllUsers] = useState<[] | WorkspaceGraphUser[]>([]);
  const [hasUsers, setHasUsers] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deploymentFailed, setDeploymentFailed] = useState(false);
  const [isWorkspaceOwner, setIsWorkspaceOwner] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const workspaceCtx = useContext(WorkspaceContext);
  const apiCall = useAuthApiCall();
  const location: any = useLocation();
  console.log("location: ", location);
  const workspace = location.state.workspace;

  const getRoles = async () => {
    await apiCall(`${ApiEndpoint.Workspaces}/${workspace.id}`, HttpMethod.Get, workspace.properties.scope_id, undefined, ResultType.JSON, (roles: Array<string>) => {
      roles && roles.includes(WorkspaceRoleName.WorkspaceOwner) && setIsWorkspaceOwner(true);
      setLoadingRoles(false);
    }, true);
  }

  useEffect(() => {
    workspace && getRoles();
  }, [workspace]);

  useEffect(() => {
    setIsLoading(true);
    workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then(async (res) => {
        await CallApiWithToken(res.accessToken, graphConfig.graphMeEndpoint, HttpMethod.Get, '')
          .then(async (response) => {
            await CallApiWithToken(res.accessToken, `${graphConfig.graphGroups}?$filter=displayName eq '${process.env.REACT_APP_ENV}-ws-${workspace.id.slice(-4)} ${graphConfig.graphGroupWorkspaceResearchers}'`, HttpMethod.Get, '')
              .then(async (response) => {
                console.log("response: ", response);
                return response.value.length > 0 ? (
                  await CallApiWithToken(res.accessToken, `${graphConfig.graphGroups}/${response.value[0].id}/members`, HttpMethod.Get, '')
                    .then(async (response) => {
                      return response.value.length > 0 ? (
                        setHasUsers(true),
                        setUsers(response.value),
                        setAllUsers(response.value),
                        setIsLoading(false)
                      ) : (
                        setHasUsers(false),
                        setIsLoading(false)
                      )
                    }).catch(err => {
                      setError(err);
                      setIsLoading(err);
                    })
                ) : (
                  setHasUsers(false),
                  setIsLoading(false)
                )
              })
              .catch(err => {
                setError(err);
                setIsLoading(false);
              })
          }).catch(err => {
            setError(err);
            setIsLoading(false);
          })
      })
    )
    workspace && setDeploymentFailed(workspace.deploymentStatus === "deployment_failed");
  }, [workspaceCtx]);

  const tableHeaders = [
    {
      header: "Users"
    },
    {
      header: "Email address",
      colSpan: workspaceCtx.workspace.templateName && workspaceCtx.workspace.templateName.includes("msl") ? 3 : 1
    }
  ];

  const onSearch = (value: string) => {
    const searchTerm = value.toLowerCase();
    let newEmailAddresses = allUsers;
    let newNames = allUsers;

    const filterByIds = newEmailAddresses.filter((item: any) => item.mail.toLowerCase().includes(searchTerm));
    const filteredByName = newNames.filter((item: any) => item.displayName.toLowerCase().includes(searchTerm));

    const searchUsers = filterByIds.concat(filteredByName);

    const newUsers = Array.from(new Set(searchUsers));
    return setUsers(newUsers);
  }

  return (
    <section className="manage-workspace">
      <LoadingBox loading={loadingRoles}>
        {!isWorkspaceOwner ? (
          <Lede>You need to have the Workspace Owner role in order to manage this workspace.</Lede>
        ) : (
          <>
            {deploymentFailed ? (
              <Lede>Deployment failed. Speak to your administrator</Lede>
            ) : (
              <>
                {workspace.properties && (
                  <>
                    <header className="manage-workspace__header">
                      <div>
                        <Title>Manage workspace users</Title>
                        <Subtitle>{workspace.properties.display_name}</Subtitle>
                      </div>
                      <Link className="manage-workspace__link" to={`/manage-workspaces/${workspace.id}/manage-workspace-services`}>Manage workspace services</Link>
                    </header>
                    <CostFigures id={workspace.id} />
                    {isLoading ? (
                      <p>loading...</p>
                    ) : (
                      <>
                        {error ? (
                          <MessageCard msgData={error} />
                        ) : (
                          <>
                            <div className="manage-workspace__sort-wrapper">
                              <ButtonLink className="manage-workspace__invite" href="https://erap.cprd.com" target="_blank">Invite new user</ButtonLink>
                            </div>
                            <SearchBox
                              className="manage-workspace__search-bar"
                              placeholder="Search for a users name or email address"
                              onSearch={(e: any) => onSearch(e.target.value)}
                            />
                            {!hasUsers && <Lede className="manage-workspaces__no-users">This workspace does not have any users</Lede>}
                            {hasUsers && (
                              <table className="manage-workspace__table">
                                <TableHead headers={tableHeaders} />
                                <tbody>
                                  {users && users.map((user: WorkspaceGraphUser) => {
                                    return (
                                      <User user={user} workspace={workspace} />
                                    )
                                  })}
                                </tbody>
                              </table>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </LoadingBox>
    </section>
  )
}

type UserProps = {
  user: WorkspaceGraphUser,
  workspace: WorkspaceType
}

const User = ({ user, workspace }: UserProps) => {
  const formatName = (displayName: string) => {
    const name = displayName.replace(".", " ");
    return name;
  }

  return (
    <tr>
      <Td className="manage-workspace__user-column">{formatName(user.displayName)}</Td>
      <Td>{user.mail}</Td>
      {workspace.templateName.includes("msl") && (
        <Td>
          <Link to={`#`}>Update</Link>
        </Td>
      )}
      {workspace.templateName.includes("msl") && (
        <Td>
          <TextButton onClick={() => console.log("not in place yet")}>Remove</TextButton>
        </Td>
      )}
    </tr>
  )
}
