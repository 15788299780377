import React, { useRef, useState, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from 'react-router-dom';
import { LabelText, HintText, Input, Button, ErrorText, ErrorSummary } from 'govuk-react';
import yn from 'yn';
import { NewWorkspaceRequest } from '../models/workspace';
import { CheckServiceDeployments } from '../../services/CheckServiceDeployments';
import { NewWorkspaceServiceRequest } from '../models/workspaceServices';
import { MessageCard } from '../Error/MessageCard';
import { Textarea } from '../ui/Textarea';
import { FormWrapper } from '../ui/FormWrapper';
import { FormLabel } from '../ui/FormLabel';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { CancelButton } from '../ui/CancelButton';

import { error } from '../../types';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

export const CreateGiteaForm=(props:any) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);

  let navigate = useNavigate(); 
  let {id} = useParams();
  const templateNameInputRef = useRef<HTMLInputElement>(null);
  const wsNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null); 
  
  
  const createGiteaHandler = (event:any) => {
    event.preventDefault();
    const enteredtemplateName = templateNameInputRef.current!.value;
    const entereddescription = descriptionInputRef.current!.value;
    const enteredvmName = wsNameInputRef.current!.value;
    
    const properties : NewWorkspaceServiceRequest["properties"] = {
      display_name: enteredvmName,
      description: entereddescription
    };

    const newWorkspaceRequest : NewWorkspaceServiceRequest = {
      templateName: enteredtemplateName,
      properties: properties
    };

    yn(process.env.REACT_APP_DEBUG) && console.log('NewWorkspace is...',newWorkspaceRequest);
    
    props.onAddImportRequest(newWorkspaceRequest);
  };

  const routeChange = () =>{ 
    let path = `/workspaces/` +id ; 
    navigate(path);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    const entereddescription = descriptionInputRef.current!.value;
    const enteredvmName = wsNameInputRef.current!.value;

    setErrors(null);
    const newErrors = [];
    if (!entereddescription || (entereddescription.length < 10 || entereddescription.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, minimum of 10 characters and maximum of 100"
      })
    }

    if (!enteredvmName || (enteredvmName.length < 5 || enteredvmName.length > 100)) {
      newErrors.push({
        targetName: "gitea-service-name",
        text: "Enter a valid Gitea service name, minimum of 5 charcters and maximum of 100"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      ) : (
        CheckServiceDeployments(
          instance,
          accounts,
          workspaceCtx.workspace.properties.scope_id,
          e,
          setErrors,
          setErrorData,
          createGiteaHandler,
          id,
          'gitea'
        ),
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      );
  }

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <FormLabel>
            <LabelText>Template name</LabelText>
            <HintText>Template name for the Gitea, which contains all configuraiton settings.</HintText>
            <Input ref={templateNameInputRef} defaultValue="tre-workspace-service-gitea" readOnly />
          </FormLabel>
          <FormLabel error={hasError("gitea-service-name")} id="gitea-service-name">
            <LabelText>Gitea service name</LabelText>
            <HintText>New Gitea name.</HintText>
            {hasError("gitea-service-name") && <ErrorText>Enter a valid name</ErrorText>}
            <Input ref={wsNameInputRef} defaultValue="Gitea Service" />
          </FormLabel>
          <FormLabel error={hasError("description")} id="description">
            <LabelText>Description</LabelText>
            <HintText>Description for the new Gitea service.</HintText>
            {hasError("description") && <ErrorText>Enter a valid description (max 100 characters)</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} defaultValue="Gitea Service" />
          </FormLabel>
          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Create service</Button>
            <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>                        
          </FormButtonContainer>  
        </FormWrapper>
      </form>
    </>
  );
}
