import { useMsal } from "@azure/msal-react";
import { CreateWorkspaceForm } from "./CreateWorkspaceForm";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingBox } from "govuk-react";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { ApiEndpoint } from "../models/apiEndPoints";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { NewWorkspaceRequest } from "../models/workspace";
import { CustomMessageCard, CustomMessageErrorCard, CustomMessageSuccessCard } from "../Error/MessageCard";
import yn from "yn";
import { FormTitle } from "../ui/FormTitle";

export const CreateWorkspace=(props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [responseData, setresponseData] = useState(null);
  const [newWorkspaceData, setnewWorkspaceData] = useState<NewWorkspaceRequest>({} as NewWorkspaceRequest);
  const [errorData, setErrorData] = useState<any>();
  const [isRequestValid, setRequestValid] = useState(false);
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);    
  let paramIds = useParams();
  
  let finalurl=`${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`;    
    
  const WorkspaceHandler = useCallback (async( newWorkspaceRequest:any) => {
    setErrorData({} as Error);
    setnewWorkspaceData(newWorkspaceRequest);
    
    let tokenRequest = {
      // ...loginRequest,
      scopes: [`${process.env.REACT_APP_TRE_CORE_API_WORKSPACE_SCOPES}/user_impersonation`],
      account: accounts[0]
    }

    if ((newWorkspaceRequest.properties.display_name?.length > 0 && newWorkspaceRequest.properties.description?.length > 0)) {
        setIsLoading(true);
        instance.acquireTokenSilent(tokenRequest).then(async (response) => {
        await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Post,newWorkspaceRequest).then(response => setresponseData(response))
        .catch((err:any)=> setErrorData(err));
            setIsLoading(false);
        }).catch((err:any) => {
          setErrorData(err);
          setIsLoading(false);
        })           
        yn(process.env.REACT_APP_DEBUG) && console.log('New workspace request submitted...');            
    } else {
      //console.log('Workspace name: length is smaller then expected...')
    }
    
  },[setnewWorkspaceData, accounts, finalurl, instance]);

  useEffect(() => {
    newWorkspaceData.properties ?
    setRequestValid((newWorkspaceData.properties.display_name?.length > 0 && newWorkspaceData.properties.description?.length > 0)):<></>
  }, [newWorkspaceData, setRequestValid]);
        //console.log('Response data is...',responseData);
  let rtnUrl = 'workspaces';
  let msgprops = {
    msgData:'New Workspace request posted for Creation and now in queued and pending, please go to resource area to view the progress...',
    linkData:rtnUrl
  }
  return (
    responseData ? (
      <>
        <h1>New workspace</h1>
        {responseData ? (
          <CustomMessageSuccessCard props={msgprops} />
        ) : (
          <CustomMessageCard msgData={errorData} />
        )}
      </>
    ) : (
      <>
        <FormTitle>New workspace request</FormTitle>
        {!isLoading && <CreateWorkspaceForm onAddImportRequest={WorkspaceHandler} />}
        {(errorData?.message === undefined || errorData === undefined || errorData?.message === '') ? (
          <div>
            {isRequestValid && <p>Please enter a valid name/desc for the Workspace (max 100 characters)</p>}
          </div>
          ) : (
            <CustomMessageErrorCard 
            props={msgprops={msgData:errorData.message,linkData:rtnUrl}} />
          )
        }
        {
          isLoading && (
            <LoadingBox loading={isLoading}>
              <p>(Loading...) Please wait while submitting the request for creating new Workspace...</p>
            </LoadingBox>
          )
        }
      </>
    )          
  );
}
