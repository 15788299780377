import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { loginRequest,  trecoreServicesConfig } from "../Core/authConfig";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { CustomMessageErrorCard } from "../Error/MessageCard";
import { AirlockReviewRequestForm } from "./AirlockReviewRequestForm";
import { ReviewTemp } from "../models/airlock";
import { ApiEndpoint } from "../models/apiEndPoints";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { AirlockDownloadcmp } from "./AirlockDownloadcmp";
import { GetStatus } from "../ui/GetTag";
import { capitalizeFirst } from "../hooks/useStringFunc";

import { Title } from "../ui/Title";
import { SummaryList } from "../ui/GDS-components/SummaryList";

import "./Airlock.css";
import { ExportTriageData } from "./ExportTriage/Review";
import { BackLink } from "../ui/GDS-components/BackLink";

export const AirlockReviewRequest = (props:any) => {

  //Review
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();    
  const [responseData, setresponseData] = useState(null);
  const [newAirlockRequestData, setnewAirlockRequestData] = useState<ReviewTemp>({} as ReviewTemp);
  const [errorData, setErrorData] = useState<Error | null>(null);
  const [isRequestValid, setRequestValid] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false);

  //Download
  const workspaceCtx = useContext(WorkspaceContext);
  const navigate = useNavigate();

  let wsId = useParams();
  let location = useLocation()
  let stateData:any = location.state;
    
  const AddReviewHandler = useCallback ( async( airlockReview:any) => {
    let finalurl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${wsId.aid}/${ApiEndpoint.AirlockReview}`;

    setErrorData(null);

    console.log("airlockReview: ", airlockReview);

    setnewAirlockRequestData(airlockReview);

    if (workspaceCtx.workspace.properties !== undefined && workspaceCtx.workspace.id === wsId.id) {
      setIsLoading(true)
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
      }).then(async (response) => {
        await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Post,airlockReview)
          .then(response => {
            setresponseData(response);
            navigate(`/workspaces/${wsId.id}/airlocks/airlock-review-request/${wsId.aid}/submitted`, {state: { approval: airlockReview.approval }});
          })
          .catch((err:any) => setErrorData(err));
        setIsLoading(false);
      }).catch((err:any) => {
        setErrorData(err);
        setIsLoading(false);                
      })
    } else {
      //console.log('length is smaller then expected...')
      console.log("this is the extra else.......");
    }
    
  },[setnewAirlockRequestData, accounts, instance, isRequestValid,wsId.id,workspaceCtx]);

  useEffect(() => {
    setRequestValid(newAirlockRequestData.decisionExplanation?.length > 0)
  }, [newAirlockRequestData, setRequestValid]);

  let rtnUrl = 'workspaces/' + wsId.id + '/airlocks';
  let msgprops = {
    msgData:'Review request submitted...',
    linkData:rtnUrl
  }

  // here 
  const fullFileName = stateData.stateObj.files[0]?.name.substring(location.pathname.indexOf("/"));
  const fileName = fullFileName ? fullFileName.substring(fullFileName.indexOf("/")) : '';

  console.log("airlockRequest: ", stateData.stateObj);
  console.log("fullFileName: ", fullFileName, "fileName: ", fileName);

  const data = [
    {
      key: "Requestor",
      value: stateData.stateObj.createdBy.name
    }, 
    {
      key: "Type",
      value: capitalizeFirst(stateData.stateObj.type)
    }, 
    {
      key: "Status",
      value: <GetStatus status={stateData.stateObj.status} />
    }, 
    {
      key: "Workspace",
      value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
    }, 
    {
      key: "Created date",
      value: new Date(stateData.stateObj.createdWhen * 1000).toLocaleDateString("en-GB")
    }, 
    {
      key: "Reason for request",
      value: stateData.stateObj.businessJustification
    }, 
    {
      key: "Submitted date",
      value: new Date(stateData.stateObj.updatedWhen * 1000).toLocaleDateString("en-GB")
    }, 
    {
      key: "File name",
      value: fileName
    }, 
    {
      key: "File size",
      value: `${(stateData.stateObj.files[0]?.size/(1024**2)).toFixed(2)} MB`
    }
  ]

  return (
    <>
      <BackLink to={`/workspaces/${wsId.id}/airlocks#exports`}>Back to requests</BackLink>
      <Title>Summary of request</Title>
      {errorData && (
        <CustomMessageErrorCard props={msgprops={msgData:errorData.message, linkData:rtnUrl}} />
      )}
      {(stateData.stateObj && workspaceCtx.workspace.properties) && (
        <>
          <SummaryList data={data} />
          {stateData.stateObj.type === "export" && (
            <ExportTriageData request={stateData.stateObj} />
          )}
          <AirlockDownloadcmp
            uploadtype={stateData.stateObj.type}
            setFileDownloaded={setFileDownloaded}
            reviewing={true}
          />
          <AirlockReviewRequestForm
            onAddReviewRequest={AddReviewHandler}
            fileDownloaded={fileDownloaded}
          />
        </>
      )}
    </>
  )
}
