import { User } from "./user";

export type FullRequest = {
  airlockRequest: AirlockRequest,
  allowedUserActions: [string]
}

export interface AirlockRequest {
  id: string,
  resourceVersion: number,
  createdBy: {
    id: string,
    name: string,
    email: string,
    roles: [
      string
    ],
    roleAssignments: [
      string
    ]
  },
  createdWhen: number,
  updatedBy: {
    id: string,
    name: string,
    email: string,
    roles: [
      string
    ],
    roleAssignments: [
      string
    ]
  },
  updatedWhen: number,
  exportReviewDueDate: number,
  isEUUAAccepted: boolean,
  history: [
    {
      resourceVersion: number,
      updatedWhen: number,
      updatedBy: {
        id: string,
        name: string,
        email: string,
        roles: [
          string
        ],
        roleAssignments: [
          string
        ]
      },
      properties: {
        previousStatus: string
      }
    }
  ],
  workspaceId: string,
  type: string,
  files: [
    {
      name: string,
      size: number
    }
  ],
  title: string,
  businessJustification: string,
  statusMessage: null | string,
  reviews: [
    {
      id: string,
      reviewer: {
        id: string,
        name: string,
        email: string,
        roles: [
          string
        ],
        roleAssignments: [
          string
        ]
      },
      dateCreated: number,
      reviewDecision: string,
      decisionExplanation: string
    }
  ],
  _etag: string,
  reviewUserResources: {},
  triageLevel: string,
  triageLevelCode: string,
  triageStatements: [
    {
      rdgConsistent: boolean,
      patientLevelData: boolean,
      requestedOutputsClear: boolean,
      requestedOutputsStatic: boolean,
      requestedOutputsPermittedFiles: boolean,
      hiddenInformation: boolean
    }
  ],
  contactTeamForm: [
    {
      requiredDisclosureAlignment?: string,
      measuresTakenMinimiseDisclosure?: string,
      transferToThirdParty?: string
    }
  ],
  statisticsStatements: [
    {
      codeLists: boolean,
      statisticalTests: boolean,
      statisticalTestsConfirmation: boolean,
      coefficientsAssociation: boolean,
      coefficientsAssociationResidualDegrees: boolean,
      coefficientsAssociationModelNotSaturated: boolean,
      coefficientsAssociationRegressionNotIncluded: boolean,
      shape: boolean,
      shapeStandardDeviations: boolean,
      shapeMinFive: boolean,
      mode: boolean,
      modeConfirmation: boolean,
      ratios: boolean,
      ratiosConfirmationNRatios: boolean,
      ratiosConfirmationHRatios: boolean,
      giniCoefficients: boolean,
      giniCoefficientsConfirmationN: boolean,
      giniCoefficientsConfirmationLessThan: boolean,
      frequencies: boolean,
      frequenciesSmallFrequenciesSuppressed: boolean,
      frequenciesZerosFullCells: boolean,
      frequenciesUnderlyingValuesIndependent: boolean,
      frequenciesCategoriesComprehensiveData: boolean,
      position: boolean,
      positionConfirmation: boolean,
      extremeValues: boolean,
      extremeValuesConfirmation: boolean,
      linearAggregates: boolean,
      linearAggregatesDerivedGroups: boolean,
      linearAggregatesPRatioDominanceRule: boolean,
      linearAggregatesNKDominanceRule: boolean,
      oddsRatios: boolean,
      oddsRatiosConfirmation: boolean,
      hazardSurvivalTables: boolean,
      hazardSurvivalTablesNumberPatientsSurvived: boolean,
      hazardSurvivalTablesExitDatesRelatives: boolean,
      hazardSurvivalTablesNoDatesWithSingleExit: boolean,
      isAcroUsedPosition: boolean,
      isAcroUsedLinearAggregates: boolean,
      other: boolean
    }
  ],
  status: string
}

export enum AirlockRequestType {
  Import = 'import',
  Export = 'export'
}

export interface NewAirlockRequest {
  type: AirlockRequestType;
  title: string;
  businessJustification: string;
  isEUUAAccepted: boolean;
}

export type AirlockFileType = {
  lastModified: number,
  lastModifiedDate: string,
  name: string,
  size: number,
  type: string,
  webkitRelativePath: string
}

export type AirlockRequestStateObjType = {
  stateObj: {
    businessJustification: string,
    isEUUAAccepted: boolean,
    type: string,
    status: string,
    title: string,
    createdWhen: string,
    createdBy: {
      name: string
    }
  },
  selectedFile: File
}

export type AirlockRequestDetailType = {
  businessJustification: string,
  isEUUAAccepted: boolean,
  type: string,
  status: string,
  title: string,
  createdWhen: number,
  createdBy: {
    name: string
  }
}

export enum AirlockRequestStatus {
  Draft = 'draft',
  InReview = 'in_review',
  Approved = 'approved',
  ApprovalInProgress = 'approval_in_progress',
  RejectionInProgress = 'rejection_in_progress',
  Rejected = 'rejected',
  Blocked = 'blocked',
  BlockedByScan = "blocked_by_scan",
  BlockingInProgress = 'blocking_in_progress',
  Submitted = 'submitted',
  Cancelled = 'cancelled',
  Failed = 'failed'
}

export enum AirlockTriage {
  Accepted = "L1: Accepted",
  NoAcro = "L2a: No ACRO",
  Acro = "L2b: ACRO",
  Exemption = "L3: Exemption",
  Rejected = "L4: Rejected",
  TriageRejection = "L4a: Rejected"
}

export enum AirlockTokenStatus {
  NotAttempted =1,
  Generated = 2,
  NotGenerated = 3    
}

export enum AirlockReviewDecision {
  Approved = 'approved',
  Rejected = 'rejected'
}

export interface AirlockReview {
  id: string,
  dateCreated: number,
  reviewDecision: AirlockReviewDecision,
  decisionExplanation: string,
  reviewer: User
}

export interface ReviewTemp  {
  approval: string,
  decisionExplanation: string
};
