import { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";
import { loginRequest, trecoreConfig } from "../components/Core/authConfig";
import { TRECoreWorkspaceData } from "../components/workspaces/TRECoreWorkspaceData";
import {MessageCard} from "../components/Error/MessageCard";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { AppRolesContext } from "../contexts/AppRolesContext";
import { RoleName } from "../components/models/roleNames";

// test data for DEV and TEST environments
import { devWorkspaceCosts, testWorkspaceCosts } from "../services/CombineWorkspacesAndCosts";
import { WorkspaceCostsType, WorkspaceType } from "../components/models/workspace";

export const Workspaces = (props:any) => {
  const [isLoading, setIsLoading] = useState(true);
  const { instance, accounts } = useMsal();    
  const [trecoreData, settrecoreData] = useState<any>();
  const [workspaceCosts, setWorkspaceCosts] = useState<any>([]);
  const [combinedData, setCombinedData] = useState<WorkspaceType[]>();
  const [errorData, setErrorData] = useState(null);
  const [updatedWorkspace, setUpdatedWorkspace] = useState<null | string>(null);
  const [refresh, setRefresh] = useState(false);
  const appRolesCtx = useContext(AppRolesContext);
  const [canSeeCosts, setCanSeeCosts] = useState(appRolesCtx.roles?.includes(RoleName.TREAdmin) ? true : false);
  
  useEffect(() =>  {
    setIsLoading(true);
    // fetch workspaces
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response: any) => {
        await CallApiWithToken(response.accessToken,`${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`,HttpMethod.Get,'')
        .then(response => {
          console.log("workspaces: ", response);
          settrecoreData(response);
          setCombinedData(response.workspaces);
          !canSeeCosts && setIsLoading(false);
        })
        .catch((err:any) => setErrorData(err));
    }).catch((err:any) => {
      setErrorData(err);
      setIsLoading(false);
    });
    // fetch workspace costs
    canSeeCosts && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: trecoreConfig.scopes
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.WorskspaceCosts}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          console.log("workspace costs response: ", response.workspace_costs_items);
          setWorkspaceCosts(response.workspace_costs_items);
          setIsLoading(false);
        }).catch((err: any) => {
          console.log("error: ", err);
          // setErrorData(err);
        })
      })
    );

    refresh && setRefresh(false);
    refresh && setUpdatedWorkspace(null);
  }, [refresh, canSeeCosts]);

  const combineData = () => {
    let combinedArray: WorkspaceType[] = [];
    console.log("workspaceCosts: ", workspaceCosts);
    canSeeCosts && trecoreData && trecoreData.workspaces.map((workspace: WorkspaceType, i: number) => {
      const combined = Object.assign({}, workspace, {costs: workspaceCosts.filter((item: WorkspaceCostsType) => item.workspace_id === workspace.id)});
      console.log("combined: ", combined);
      combinedArray.push(combined);
      setIsLoading(false);
    })
    return (
      setCombinedData(combinedArray)
    );
  }

  useEffect(() => {
    workspaceCosts && combineData()
  }, [trecoreData, workspaceCosts]);

  console.log("canSeeCosts: ", canSeeCosts);
  console.log("combinedData: ", combinedData);
  console.log("trecoreData: ", trecoreData);
  console.log("workspaceCosts: ", workspaceCosts);

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  return (    
    <>
      {canSeeCosts && combinedData && (
        <TRECoreWorkspaceData
          wslistParam={combinedData}
          updatedWorkspace={updatedWorkspace}
          setRefresh={setRefresh}
          canSeeCosts={canSeeCosts}
          setCanSeeCosts={setCanSeeCosts}
        />
      )}
      {!canSeeCosts && trecoreData && (
        <TRECoreWorkspaceData
          wslistParam={trecoreData.workspaces}
          updatedWorkspace={updatedWorkspace}
          setRefresh={setRefresh}
          canSeeCosts={canSeeCosts}
          setCanSeeCosts={setCanSeeCosts}
        />
      )}
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
    </>
  );
}
