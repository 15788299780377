import React, { useRef, useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ErrorText, LabelText, LoadingBox, Button, ErrorSummary } from 'govuk-react';
import { useMsal } from '@azure/msal-react';

import { error } from '../../../../types';

import { Title } from '../../../ui/Title';
import { FormWrapper } from '../../../ui/FormWrapper';
import { FormLabel } from '../../../ui/FormLabel';
import { Textarea } from '../../../ui/Textarea';
import { MessageCard } from '../../../Error/MessageCard';

import { loginRequest, trecoreServicesConfig } from '../../../Core/authConfig';
import { WorkspaceContext } from '../../../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../../../Core/fetch';
import { ApiEndpoint } from '../../../models/apiEndPoints';
import { Lede } from '../../../ui/Lede';
import { IsOnlineContext } from '../../../../contexts/IsOnlineContext';

import './index.css';

export const AirlockExportTriageExemption = () => {
  const requiredOutputsInputRef = useRef<HTMLTextAreaElement>(null);
  const minimiseDisclosiveRiskInputRef = useRef<HTMLTextAreaElement>(null);
  const thirdPartyInputRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const navigate = useNavigate();
  const params = useParams();
  const wsId = params.id;
  const arId = params.aid;
  const location = useLocation();
  const state: any = location.state;

  const createPayload = (e: any) => {
    e.preventDefault();
    const requiredOutputs = requiredOutputsInputRef.current!.value;
    const minimiseDisclosiveRisk = minimiseDisclosiveRiskInputRef.current!.value;
    const thirdParty = thirdPartyInputRef.current!.value;

    const payload: any = {
      "requiredDisclosureAlignment": requiredOutputs,
      "measuresTakenMinimiseDisclosure": minimiseDisclosiveRisk,
      "transferToThirdParty": thirdParty
    }

    setLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (initialResponse) => {
      await CallApiWithToken(
        initialResponse.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockContact}`,
        HttpMethod.Post,
        payload
      ).then(async (response) => {
        await CallApiWithToken(
          initialResponse.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId}/${ApiEndpoint.AirlockRequests}/${arId}/${ApiEndpoint.AirlockSubmit}`,
          HttpMethod.Post,
          null
        ).then(response => {
          navigate(`/workspaces/${wsId}/airlocks#exports`, { state: { requestComplete: true, requestMessage: "Due to the complexity of your outputs, we have escalated the review to specialists within our team. We aim to complete this review within 15 working days, and we will let you know when the assessment is complete and whether you can download your outputs.", aID: arId } });
          setLoading(false);
        }).catch((err: any) => {
          console.log("err: ", err);
          setErrorData(err);
          setLoading(false);
        })
      }).catch((err: any) => {
        console.log("err: ", err);
        setErrorData(err);
        setLoading(false);
      })
    })
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();

    setErrors(null);
    const newErrors = [];

    const requiredOutputs = requiredOutputsInputRef.current?.value;
    if (!requiredOutputs || requiredOutputs.length > 1500) {
      newErrors.push({
        targetName: "required-outputs",
        text: "Enter a valid reason"
      })
    }

    const minimiseDisclosiveRisk = minimiseDisclosiveRiskInputRef.current?.value;
    if (!minimiseDisclosiveRisk || minimiseDisclosiveRisk.length > 1500) {
      newErrors.push({
        targetName: "disclosive-risk",
        text: "Enter a valid reason"
      })
    }

    const thirdParty = thirdPartyInputRef.current?.value;
    if (!thirdParty || thirdParty.length > 1500) {
      newErrors.push({
        targetName: "third-party",
        text: "Enter a valid reason"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("error-boundary")?.scrollIntoView()
    ) : createPayload(e);
  }

  return (
    <LoadingBox loading={loading}>
      {isOnlineCtx.isOnline ? (
        <Lede>Export function is only available in your CPRD Safe workspace</Lede>
      ) : (
        <>
          {errorData && (
            <MessageCard msgData={errorData} />
          )}
          <Title>Export exemption request</Title>
          <p className="airlock-export-triage-exemption__copy">Your export request does not meet CPRD disclosure control rules. Provide additional information for further consideration:</p>
          <div id="error-boundary">
            {errors && errors.length > 0 && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem with your request"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
          </div>
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <FormLabel error={hasError("required-outputs")} id="required-outputs">
                <LabelText>Why are outputs required that do not align with the disclosure control rules?</LabelText>
                {hasError("required-outputs") && <ErrorText>Enter a valid reason (maximum of 1,500 characters)</ErrorText>}
                <Textarea className="airlock-export-triage-exemption__textarea" forwardRef={requiredOutputsInputRef} />
              </FormLabel>
              <FormLabel error={hasError("disclosive-risk")} id="disclosive-risk">
                <LabelText>What measures have been taken to minimise the risk of potentially disclosive outputs?</LabelText>
                {hasError("disclosive-risk") && <ErrorText>Enter a valid reason (maximum of 1,500 characters)</ErrorText>}
                <Textarea className="airlock-export-triage-exemption__textarea" forwardRef={minimiseDisclosiveRiskInputRef} />
              </FormLabel>
              <FormLabel error={hasError("third-party")} id="third-party">
                <LabelText>Will the outputs be transferred to any other third party?</LabelText>
                {hasError("third-party") && <ErrorText>Enter a valid reason (maximum of 1,500 characters)</ErrorText>}
                <Textarea className="airlock-export-triage-exemption__textarea" forwardRef={thirdPartyInputRef} />
              </FormLabel>
              <Button className="airlock-export-triage-exemption__submit govuk-button" type="submit">Submit</Button>
            </FormWrapper>
          </form>
        </>
      )}
    </LoadingBox>
  )
}
