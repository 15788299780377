import React, { useRef, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ErrorSummary, ErrorText, Heading, HintText, LabelText, Input, Button, Link, LoadingBox, UnorderedList, ListItem } from "govuk-react";
import { useMsal } from "@azure/msal-react";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { error } from "../../types";

import { FormWrapper } from "../ui/FormWrapper";
import { FormLabel } from "../ui/FormLabel";
import { Textarea } from "../ui/Textarea";
import { FormButtonContainer } from "../ui/FormButtonContainer";

import { AirlockRequestType } from "../models/airlock";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ApiEndpoint } from "../models/apiEndPoints";
import { MessageCard } from "../Error/MessageCard";

import "./AirlockCreate.css";
import uploadFileToBlob, { checkBlobsInContainer } from "../Core/azureStorageBlob";
import { Lede } from "../ui/Lede";
import { Checkbox } from "../ui/Checkbox";
import { IsOnlineContext } from "../../contexts/IsOnlineContext";

type AirlockCreateProps = {
  type: string;
}

export const AirlockCreate = ({ type }: AirlockCreateProps) => {
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const [incorrectFileType, setIncorrectFileType] = useState<null | boolean>(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [arId, setArId] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const wsId = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const requestNameInputRef = useRef<HTMLInputElement>(null);
  const businessJustificationInputRef = useRef<HTMLTextAreaElement>(null);
  const fileUploadInputRef = useRef<HTMLInputElement>(null);

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const allowedExtensions = [".ado", ".bmp", ".csv", ".do", ".dta", ".gif", ".gph", ".gz", ".ipynb", ".jpeg", ".jpg", ".json", ".log", ".mata", ".md", ".notebook", ".pdf", ".pkg", ".png", ".py", ".py3", ".r", ".rda", ".rdata", ".rds", ".rmd", ".rst", ".smcl", ".sql", ".sthlp", ".svg", ".tar", ".tar.gz", ".tex", ".toc", ".tsv", ".txt", ".xls", ".xlsm", ".xlsx", ".zip", ".ppb", ".pmb"];

  const checkFileType = (e: React.BaseSyntheticEvent) => {
    if (e.target.value.length > 0) {
      const string = e.target.value;
      const subString = string.substring(string.lastIndexOf(".") - 0).toLowerCase();
      console.log("subString: ", subString);
      const newFileName = `${string.substring(0, string.lastIndexOf("."))}${subString}`;
      const removeStart = newFileName.substring(0, newFileName.lastIndexOf("/") - 0);
      const checkExtension = allowedExtensions.findIndex(element => element.includes(subString));
      console.log("new file name: ", newFileName);
      console.log("files: ", e.target.files[0]);
      console.log("removeStart: ", removeStart);
      
      const file = new File([], removeStart, {
        type: e.target.files[0].type,
        lastModified: e.target.files[0].lastModified,
      });

      console.log("file: ", file);

      setIncorrectFileType(false);

      if (checkExtension === -1) {
        // not a valid file type
        setIncorrectFileType(true);
        setSelectedFile(null);
      } else {
        // valid file type
        setIncorrectFileType(false);
        setSelectedFile(e.target.files[0]);
      }
    }
  }

  const createPayload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const enteredRequestTitle = requestNameInputRef.current!.value;
    const enteredBusinessJustification = businessJustificationInputRef.current!.value;

    // create payload with name and business justification
    const payload: any = {
      type: type === "export" ? AirlockRequestType.Export : AirlockRequestType.Import,
      title: enteredRequestTitle,
      businessJustification: enteredBusinessJustification,
      isEUUAAccepted: type === "export" ? true : acceptedTerms
    }



    // call API to create airlock request with name and business justification only
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (initialResponse) => {
      await CallApiWithToken(
        initialResponse.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}`,
        HttpMethod.Post,
        payload
      ).then(async (response) => {
        // retrieve airlock request id from response, setArId
        console.log("airlock request created");
        setArId(response.airlockRequest.id);
        // get sasToken for upload
        await CallApiWithToken(
          initialResponse.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${response.airlockRequest.id}/${ApiEndpoint.AirlockLink}`,
          HttpMethod.Get,
          null
        ).then(async (sasTokenResponse) => {
          // upload file
          console.log("sasToken created");
          // sasTokenResponse.containerUrl
          await uploadFileToBlob(
            selectedFile,
            sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
            response.airlockRequest.id
          ).then(async (fileRresponse) => {
            // check file has been uploaded
            console.log("check file has been upload");
            await checkBlobsInContainer(
              sasTokenResponse.containerUrl.replace(response.airlockRequest.id,''),
              response.airlockRequest.id
            ).then((checkBlobResponse) => {
              // successful
              console.log("successful");
              type === "export" ? (
                navigate(`/workspaces/${wsId.id}/airlocks/airlock-export-triage-stage-1/${response.airlockRequest.id}`, {state: { selectedFile: selectedFile }})
              ) : (
                navigate(`/workspaces/${wsId.id}/airlocks/airlock-import-submit/${response.airlockRequest.id}`, {state: { stateObj: response.airlockRequest, selectedFile: selectedFile }})
              );
            }).catch((err: any) => {
              setErrorData(err);
              setLoading(false);
            })
          }).catch((err: any) => {
            setErrorData(err);
            setLoading(false);
          })
        }).catch((err: any) => {
          setErrorData(err);
          setLoading(false);
        })
      }).catch((err: any) => {
        setErrorData(err);
        console.log("err: ", err);
        setLoading(false);
      })
    })
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setErrors(null);
    const newErrors = [];

    const name = requestNameInputRef.current?.value;
    if (!name || (name.length < 11 || name.length > 100)) {
      newErrors.push({
        targetName: "request-name",
        text: "Enter a valid name"
      })
    }

    const reason = businessJustificationInputRef.current?.value;
    if (!reason || (reason.length < 11 || reason.length > 200)) {
      newErrors.push({
        targetName: "request-justification",
        text: "Enter a valid reason"
      })
    }

    const file = fileUploadInputRef.current?.value;
    if (!file || incorrectFileType) {
      newErrors.push({
        targetName: "file-upload",
        text: "Upload a valid file"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      document.getElementById("error-boundary")?.scrollIntoView()
    ) : createPayload(e);
  }

  // add in check for maximum of 10 export requests
  return (
    <LoadingBox loading={loading}>
      {(isOnlineCtx.isOnline && type === "export") || (!isOnlineCtx.isOnline && type === "import") ? (
        <>
          {type === "export" ? (
            <Lede>Export function is only available in your CPRD Safe workspace</Lede>
          ) : (
            <Lede>Import function is only available outside your CPRD Safe workspace</Lede>
          )}
        </>
      ) : (
        <>
          {errorData && (
            <MessageCard msgData={errorData} />
          )}
          <div id="error-boundary">
            {errors && errors.length > 0 && (
              <ErrorSummary
                errors={errors}
                heading="There is a problem submitting your request"
                onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
              />
            )}
          </div>
          <Heading as="h1" size="SMALL">
            {type === "export" ? (
              "New export request 1/3"
            ) : (
              "New import request"
            )}
          </Heading>
          {type === "export" ? (
            <>
              <Heading as="h2">Setup information</Heading>
              <AirlockForm
                businessJustificationInputRef={businessJustificationInputRef}
                checkFileType={checkFileType}
                fileUploadInputRef={fileUploadInputRef}
                hasError={hasError}
                incorrectFileType={incorrectFileType}
                onSubmit={onSubmit}
                requestNameInputRef={requestNameInputRef}
                wsId={wsId.id}
              />
            </>
          ) : (
            <>
              {acceptedTerms ? (
                <>
                  <Heading as="h2">Setup information</Heading>
                  <AirlockForm
                    businessJustificationInputRef={businessJustificationInputRef}
                    checkFileType={checkFileType}
                    fileUploadInputRef={fileUploadInputRef}
                    hasError={hasError}
                    incorrectFileType={incorrectFileType}
                    onSubmit={onSubmit}
                    requestNameInputRef={requestNameInputRef}
                    wsId={wsId.id}
                  />
                </>
              ) : (
                <>
                  <Heading as="h2">Confirm acceptance</Heading>
                  <ImportTerms setTerms={setAcceptedTerms} wsId={wsId.id} />
                </>
              )}
            </>
          )}
        </>
      )}
    </LoadingBox>
  )
}

export const AirlockForm = ({
    onSubmit,
    hasError,
    requestNameInputRef,
    businessJustificationInputRef,
    incorrectFileType,
    checkFileType,
    fileUploadInputRef,
    wsId
  }: any) => {
  return (
    <form onSubmit={onSubmit}>
      <FormWrapper>
        <FormLabel error={hasError("request-name")} id="request-name">
          <LabelText>Request name</LabelText>
          <HintText>Provide a name for your request</HintText>
          {hasError("request-name") && <ErrorText>Enter a valid request name (between 10 and 100 characters)</ErrorText>}
          <Input ref={requestNameInputRef} />
        </FormLabel>
        <FormLabel error={hasError("request-justification")} id="request-justification">
          <LabelText>Request justification</LabelText>
          <HintText>Provide justification for your request</HintText>
          {hasError("request-justification") && <ErrorText>Enter a reason for your request (between 10 and 200 characters)</ErrorText>}
          <Textarea forwardRef={businessJustificationInputRef} />
        </FormLabel>
        <FormLabel error={hasError("file-upload")} id="file-upload">
          <LabelText>Submit a new request into CPRD Safe by selecting your file or zip file, which will then be sent to the airlock manager for authorisation.</LabelText>
          <HintText>Only upload a single file, zip file is also supported. Allowed file types: .ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .png, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip, .ppb, .pmb.</HintText>
          {hasError("file-upload") && <ErrorText>Upload a file</ErrorText>}
          {incorrectFileType && <ErrorText>Upload a valid file type (.ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .png, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip, .ppb, .pmb)</ErrorText>}
          <input
            className="govuk-file-upload"
            id="file-upload-1"
            name="file-upload-1"
            type="file"
            onChange={checkFileType}
            accept=".ado, .bmp, .csv, .do, .dta, .gif, .gph, .gz, .ipynb, .jpeg, .jpg, .json, .log, .mata, .md, .notebook, .pdf, .pkg, .png, .py, .py3, .r, .rda, .rdata, .rds, .rmd, .rst, .smcl, .sql, .sthlp, .svg, .tar, .tar.gz, .tex, .toc, .tsv, .txt, .xls, .xlsm, .xlsx, .zip, .ppb, .pmb"
            ref={fileUploadInputRef}
          />
        </FormLabel>
        <FormButtonContainer>
          <Button className="airlock-create__button govuk-button" type="submit">Continue</Button>
          <Link href={`/workspaces/${wsId}/airlocks`}>Cancel</Link>
        </FormButtonContainer>
      </FormWrapper>
    </form>
  )
}

export const ImportTerms = ({ setTerms, wsId }: any) => {
  const [agreedTerms, setAgreedTerms] = useState(false);
  const navigate = useNavigate();

  const backToDashboard = () => {
    navigate(`/workspaces/${wsId}/airlocks/`, {state: { message: "You must accept the terms in order to import files into CPRD Safe." }})
  }

  return (
    <>
      <p>In requesting to import data to CPRD Safe, I confirm the following:</p>
      <UnorderedList>
        <ListItem>The requested import is owned by my organisation, and I am authorised to upload the data on behalf of my organisation, or the import has otherwise been pre-approved by CPRD.</ListItem>
        <ListItem>The requested import does not include any of the following content:</ListItem>
        <UnorderedList>
          <ListItem>Files containing or suspected of containing malware</ListItem>
          <ListItem>Files containing patient or event-level data that have not been provided by CPRD</ListItem>
          <ListItem>Files containing personal data (as defined by applicable data protection laws)</ListItem>
          <ListItem>Files that present a risk of re-identification of CPRD Data if used in conjunction with the CPRD Dataset within <strong>CPRD Safe</strong> (unless the use of these files has been specifically approved as part of a protocol approved by CPRD in line with applicable policies and procedures).</ListItem>
        </UnorderedList>
      </UnorderedList>
      <Checkbox
        label="I accept"
        onChange={() => setAgreedTerms(!agreedTerms)}
      />
      <FormButtonContainer>
        <Button className="govuk-button" onClick={() => setTerms(true)} disabled={!agreedTerms}>Accept</Button>
        <Button className="govuk-button" onClick={() => backToDashboard()}>Decline</Button>
      </FormButtonContainer>
    </>
  )
}




