import type { ComponentPropsWithRef } from 'react';
import './Textarea.css';

interface TextareaProps extends ComponentPropsWithRef<"textarea"> {
  forwardRef: React.LegacyRef<HTMLTextAreaElement>,
  className?: string,
}

export const Textarea = ({ forwardRef, className, ...props }: TextareaProps) => {
  const classNames = `Textarea${className ? ` ${className}` : ''}`;
  return <textarea className={classNames} ref={forwardRef} {...props} rows={5} />
}
