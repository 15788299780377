import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Heading, LoadingBox, Button, WarningText } from 'govuk-react';
import { loginRequest, trecoreServicesConfig } from '../Core/authConfig';
import { ApiEndpoint } from '../models/apiEndPoints';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { AppRolesContext } from '../../contexts/AppRolesContext';
import { WorkspaceRoleName, RoleName } from '../models/roleNames';
import { MessageCard } from '../Error/MessageCard';
import { Selectbox } from '../ui/Selectbox';
import { GetStatusTag } from '../ui/GetTag';
import { AirlockRequestStatus, AirlockTriage } from '../models/airlock';
import { Td, Th } from '../ui/GDS-components/Table';
import { SearchBox } from '../ui/SearchBox';
import { Tabs } from '../ui/GDS-components/Tabs';
import Pagination from '../ui/Pagination';
import { ButtonWithSort } from '../ui/ButtonWithSort';

import './AirlockDashboard.css';
import { useAuthApiCall } from '../hooks/useAuthAPICall';
import { NotificationBox } from '../ui/NotificationBox';
import { TextButton } from '../ui/TextButton';
import { IsOnlineContext } from '../../contexts/IsOnlineContext';

export const AirlockDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const [requests, setRequests] = useState<any>(null);
  const [allRequests, setAllRequests] = useState<any>(null);
  const [imports, setImports] = useState<any>(null);
  const [allImports, setAllImports] = useState<any>(null);
  const [importData, setImportData] = useState<any>(null);
  const [exports, setExports] = useState<any>(null);
  const [allExports, setAllExports] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null);
  const [sortSubmittedAsc, setSortSubmittedAsc] = useState<boolean>(false);
  const [sortReviewedAsc, setSortReviewedAsc] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<null | string>();
  const [recordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredBy, setFilteredBy] = useState<{ value?: string, type?: string}[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const typeFilterInputRef = useRef<HTMLSelectElement>(null);
  const statusFilterInputRef = useRef<HTMLSelectElement>(null);
  const requestorFilterInputRef = useRef<HTMLSelectElement>(null);

  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData: any = location.state;

  // if a user is a workspace researcher only, only retrieve their requests
  const researchUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}?&creator_user_id=${appRolesCtx.OId}`;
  // if a user has multiple workspace roles, retrieve all requests for the workspace
  const airlockManagerUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}`;
  const isResearcher = workspaceCtx.roles.length === 1 && workspaceCtx.roles[0] === WorkspaceRoleName.WorkspaceResearcher;

  const getReviewerRequests = (items: any) => {
    // to remove draft requests from the list that are not created by the workspace owner/airlock manager
    const fullList = items;
    const drafts = fullList.filter((item: any) => item.airlockRequest.status === AirlockRequestStatus.Draft);
    const draftsNotByReviewer = drafts.filter((item: any) => item.airlockRequest.createdBy.id !== appRolesCtx.OId);
    return items.filter((item: any) => !draftsNotByReviewer.includes(item));
  }

  useEffect(() => {
    setLoading(true);
    workspaceCtx.workspace.properties && workspaceCtx.roles.length > 0 && appRolesCtx.OId.length > 0 && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (reponse) => {
        await CallApiWithToken(
          reponse.accessToken,
          isResearcher ? researchUrl : airlockManagerUrl,
          HttpMethod.Get,
          ''
        ).then(response => {
          let conditionalRequests = isResearcher ? (
            response.airlockRequests
          ) : (
            getReviewerRequests(response.airlockRequests)
          );

          setRequests(conditionalRequests);
          setAllRequests(conditionalRequests);
          setExports(conditionalRequests.filter((item: any) => item.airlockRequest.type === "export"));
          setAllExports(conditionalRequests.filter((item: any) => item.airlockRequest.type === "export"));
          setImports(conditionalRequests.filter((item: any) => item.airlockRequest.type === "import"));
          setAllImports(conditionalRequests.filter((item: any) => item.airlockRequest.type === "import"));
          setActiveTab(location.hash === "#exports" ? "exports" : "imports");
          setLoading(false);
        }).catch((err: any) => {
          console.log("err: ", err);
          setErrorData(err);
          setLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  useEffect(() => {
    activeTab && (imports || exports) && (
      activeTab === "imports" ? (
        setImportData(imports.slice(indexOfFirstRecord, indexOfLastRecord))
      ) : (
        setExportData(exports.slice(indexOfFirstRecord, indexOfLastRecord))
      )
    )
  }, [activeTab, imports, exports, currentPage]);

  useEffect(() => {
    filteredBy.length > 0 ? (
      filteredBy.map((filter: any, i: number) => {
        const importData = i === 0 ? allImports : imports;
        const exportData = i === 0 ? allExports : exports;
        return addFilter(filter.type, filter.value, importData, exportData);
      })
    ) : (
      resetFilters()
    );
  }, [filteredBy]);

  const addFilter = (type: string, value: string, imports: any, exports: any) => {
    switch (type) {
      case "onFilterType":
        return onFilterType(value, exports);
      case "onStatusFilter":
        return onStatusFilter(value, imports, exports);
      case "onFilterRequestor":
        return onFilterRequestor(value, imports, exports);
      case "onSearch":
        return onSearch(value, imports, exports);
    }
  }

  const resetFilters = () => {
    setImports(allImports);
    setExports(allExports);
  }

  const clearFilters = () => {
    searchInputRef.current!.value = "";
    typeFilterInputRef.current!.options[0].selected = true;
    statusFilterInputRef.current!.options[0].selected = true;
    requestorFilterInputRef.current!.options[0].selected = true;
    setFilteredBy([]);
    resetFilters();
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = activeTab === "imports" ? imports && Math.ceil(imports.length / recordsPerPage) : exports && Math.ceil(exports.length / recordsPerPage);

  const routeChange = (path: string) => {
    navigate(path);
  }

  const filterResults = (value: string, type: string) => {
    if (value === "") {
      setFilteredBy(filteredBy.filter(e => e.type !== type));
    } else {
      const filters = filteredBy;
      filters.push({ value: value, type: type});
      setFilteredBy([...filters]);
    }
  }

  const typeFilters = [
    {
      name: "Type",
      value: ""
    },
    {
      name: "Low risk outputs/statistics",
      value: AirlockTriage.Accepted
    },
    {
      name: "Medium risk - with ACRO",
      value: AirlockTriage.Acro
    },
    {
      name: "Meduim risk - without ACRO",
      value: AirlockTriage.NoAcro
    },
    {
      name: "High risk - Exceptions",
      value: AirlockTriage.Exemption
    },
    {
      name: "Disallowed - Rejected",
      value: "rejected"
    },
    {
      name: "Disallowed - Auto rejected",
      value: "auto-rejected"
    }
  ];

  const onSearch = (value: string, importData: any, exportData: any) => {
    // "Search by name, id, type or requestor"
    let newExportList = exportData;
    const exportsFilteredByName = newExportList && newExportList.filter((item: any) => item.airlockRequest.title.includes(value));
    const exportsFilteredById = newExportList && newExportList.filter((item: any) => item.airlockRequest.id.includes(value));
    const exportsFilteredByRequestor = newExportList && newExportList.filter((item: any) => item.airlockRequest.createdBy.name.includes(value));
    const searchExportRequests = exportsFilteredByName.concat(exportsFilteredById, exportsFilteredByRequestor);
    const newExportRequests = Array.from(new Set(searchExportRequests));

    let newImportList = importData;
    const importsFilteredByName = newImportList && newImportList.filter((item: any) => item.airlockRequest.title.includes(value));
    const importsFilteredById = newImportList && newImportList.filter((item: any) => item.airlockRequest.id.includes(value));
    const importsFilteredByRequestor = newImportList && newImportList.filter((item: any) => item.airlockRequest.createdBy.name.includes(value));
    const searchImportRequests = importsFilteredByName.concat(importsFilteredById, importsFilteredByRequestor);
    const newImportRequests = Array.from(new Set(searchImportRequests));

    setExports(newExportRequests);
    setImports(newImportRequests);
  }

  const onFilterType = (value: string, exportData: any) => {
    let newExportList = exportData;
    let filterByType;
    if (value === "auto-rejected") {
      filterByType = newExportList && newExportList.filter((item: any) => item.airlockRequest.triageLevel.includes(AirlockTriage.Rejected) || item.airlockRequest.triageLevel.includes(AirlockTriage.TriageRejection));
    } else if (value === "rejected") {
      const rejectedFilter = newExportList && newExportList.filter((item: any) => item.airlockRequest.status === AirlockRequestStatus.Rejected);
      filterByType = rejectedFilter.filter((item: any) => !item.airlockRequest.triageLevel.includes(AirlockTriage.Rejected || AirlockTriage.TriageRejection));
    } else {
      filterByType = newExportList && newExportList.filter((item: any) => item.airlockRequest.triageLevel.includes(value));
    }

    return setExports(filterByType)
  }

  const statusFilters = [
    {
      name: "Status",
      value: ""
    },
    {
      name: "Approved",
      value: AirlockRequestStatus.Approved
    },
    {
      name: "Approval in progress",
      value: AirlockRequestStatus.ApprovalInProgress
    },
    {
      name: "Blocked by scan",
      value: AirlockRequestStatus.BlockedByScan
    },
    {
      name: "Blocking in progress",
      value: AirlockRequestStatus.BlockingInProgress
    },
    {
      name: "Cancelled",
      value: AirlockRequestStatus.Cancelled
    },
    {
      name: "Draft",
      value: AirlockRequestStatus.Draft
    },
    {
      name: "In review",
      value: AirlockRequestStatus.InReview
    },
    {
      name: "Rejected",
      value: AirlockRequestStatus.Rejected
    },
    {
      name: "Submitted",
      value: AirlockRequestStatus.Submitted
    }
  ];

  const onStatusFilter = (value: string, importData: any, exportData: any) => {
    let newImportList = importData;
    const filterImportsByStatus = newImportList && newImportList.filter((item: any) => item.airlockRequest.status.toLowerCase().includes(value));
    let newExportList = exportData;
    const filterExportsByStatus = newExportList && newExportList.filter((item: any) => item.airlockRequest.status.toLowerCase().includes(value));

    return (
      setImports(filterImportsByStatus),
      setExports(filterExportsByStatus)
    )
  }

  const getRequestors = () => {
    const requestors = [
      {
        name: "Requestor",
        value: ""
      },
    ];

    let newRequestList = allRequests;
    newRequestList.map((item: any) => {
      const requestor = {
        name: item.airlockRequest.createdBy.name,
        value: item.airlockRequest.createdBy.name
      }
      return requestors.push(requestor);
    })

    const uniqueSet = Array.from(new Set(requestors.map(item => item.name))).map(name => {
      return requestors.find(item => item.name === name)
    });

    return uniqueSet.length > 1 ? uniqueSet : requestors;
  }

  const onFilterRequestor = (value: string, importData: any, exportData: any) => {
    let newExportList = exportData;
    const exportsFilteredByRequestor = newExportList && newExportList.filter((item: any) => item.airlockRequest.createdBy.name.includes(value));
    let newImportList = importData;
    const importsFilteredByRequestor = newImportList && newImportList.filter((item: any) => item.airlockRequest.createdBy.name.includes(value));

    return (
      setImports(importsFilteredByRequestor),
      setExports(exportsFilteredByRequestor)
    )
  }

  const onActivateTab = (tabToActivate: string) => {
    setActiveTab(tabToActivate);
    navigate(`/workspaces/${workspaceCtx.workspace.id}/airlocks#${tabToActivate}`);
    setCurrentPage(1);
  }

  const sortRequests = (value: string, toSort: any, asc: boolean) => {
    const sortedRequests = (type: any) => {
      let sortedData = type;

      sortedData.sort((a: any, b:any) => {
        const submittedDateA = a.airlockRequest.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Submitted);
        const submittedDateB = b.airlockRequest.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Submitted);
        const A = value === "reviewed-date" ? ( 
          a.airlockRequest.reviews.length > 0 ? a.airlockRequest.reviews[0].dateCreated : 0
         ) : (
          a.airlockRequest.history.length > 0 ? submittedDateA !== undefined ? submittedDateA.updatedWhen : 0 : 0
         );

        const B = value === "reviewed-date" ? ( 
          b.airlockRequest.reviews.length > 0 ? b.airlockRequest.reviews[0].dateCreated : 0
         ) : (
          b.airlockRequest.history.length > 0 ? submittedDateB !== undefined ? submittedDateB.updatedWhen : 0 : 0
         );

         if (A < B) {
          return asc ? -1 : 1;
         }
         if (A > B) {
          return asc ? 1 : -1;
         }
         return 0;
      });

      return sortedData;
    }

    return sortedRequests(toSort)
  }

  const onSort = (e: string) => {
    e === "reviewed-date" ? setSortReviewedAsc(!sortReviewedAsc) : setSortSubmittedAsc(!sortSubmittedAsc);
    const asc = e === "reviewed-date" ? sortReviewedAsc : sortSubmittedAsc;

    setImports([...sortRequests(e, imports, !asc)]);
    setExports([...sortRequests(e, exports, !asc)]);
  }

  return (
    <LoadingBox loading={loading}>
      {location.state && stateData.message && (
        <NotificationBox
          error={null}
          text=""
          updated={stateData.message}
        />
      )}
      <Heading as="h1">Requests</Heading>
      {errorData ? (
        <MessageCard msgData={errorData} />
      ) : (
        <>
          <div className="airlock-dashboard__actions-container">
            <div className="airlock-dashboard__filters-wrapper">
              <Selectbox
                className="airlock-dashboard__selectbox"
                label="Filter by"
                options={typeFilters}
                onChange={(e: string) => filterResults(e, "onFilterType")}
                defaultValue="Type"
                disabled={activeTab === "imports"}
                forwardRef={typeFilterInputRef}
                hideSelect
              />
              <Selectbox
                className="airlock-dashboard__selectbox"
                label="Filter by"
                options={statusFilters}
                onChange={(e: string) => filterResults(e, "onStatusFilter")}
                defaultValue="Status"
                forwardRef={statusFilterInputRef}
                hideSelect
              />
              <Selectbox
                className="airlock-dashboard__selectbox"
                label="Filter by"
                options={imports && getRequestors()}
                onChange={(e: string) => filterResults(e, "onFilterRequestor")}
                defaultValue="Requestor"
                forwardRef={requestorFilterInputRef}
                hideSelect
              />
            </div>
            <div className="airlocks-dashboard__buttons-wrapper">
              {(workspaceCtx.roles?.includes(WorkspaceRoleName.AirlockManager) || workspaceCtx.roles?.includes(RoleName.TREAdmin)) && (
                <Button
                  buttonColour="#f3f2f1"
                  buttonShadowColour="#929191"
                  buttonTextColour="#0b0c0c"
                  onClick={() => routeChange(`/Workspaces/${id}/AirlockAdminHistory`)}
                >
                  View history
                </Button>
              )}
              {(workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceResearcher)) && (
                <Button
                  className="govuk-button"
                  onClick={() => routeChange("create-new-airlock-request")}
                >
                  Create request
                </Button>
              )}
            </div>
          </div>
          <SearchBox
            placeholder="Search by name, id or requestor"
            onSearch={(e) => filterResults(e.target.value, "onSearch")}
            forwardRef={searchInputRef}
          />
          <Button className="govuk-button airlock-dashboard__clear-filters" onClick={() => clearFilters()}>Clear filters</Button>
          {isOnlineCtx.isOnline && (workspaceCtx.roles?.includes(WorkspaceRoleName.AirlockManager) || workspaceCtx.roles?.includes(RoleName.TREAdmin) || workspaceCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner)) && (
            <p className="airlock-dashboard__warning-text">
              <WarningText>You must be inside CPRD Safe to download files</WarningText>
            </p>
          )}
        </>
      )}
      {activeTab && (
        <Tabs
          activeTab={activeTab}
          setActiveTab={onActivateTab}
          tabs={[
            {
              label: "Import requests",
              name: "imports"
            },
            {
              label: "Export requests",
              name: "exports"
            }
          ]}
        >
          <table>
            <thead>
              <tr>
                <Th>Name</Th>
                <Th>Airlock request ID</Th>
                <Th>
                  <ButtonWithSort onClick={() => onSort("submitted-date")}>Submitted date</ButtonWithSort>
                </Th>
                <Th>
                  <ButtonWithSort onClick={() => onSort("reviewed-date")}>Reviewed date</ButtonWithSort>
                </Th>
                <Th colspan={3}>Status</Th>
              </tr>
            </thead>
            <tbody>
              {activeTab === "imports" && importData && (
                importData.map((request: any) => <ImportDataRow key={request.id} isOnline={isOnlineCtx.isOnline} data={request.airlockRequest} isResearcher={isResearcher} />)
              )}
              {activeTab === "exports" && exportData && (
                exportData.map((request: any) => <ExportDataRow key={request.id} isOnline={isOnlineCtx.isOnline} data={request.airlockRequest} isResearcher={isResearcher} />)
              )}
            </tbody>
          </table>
        </Tabs>
      )}
      {nPages > 1 && (
        <Pagination
          className="airlock-dashboard__pagination"
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </LoadingBox>
  )
}

const ImportDataRow = ({ isOnline, data, isResearcher }: any) => {
  const { id } = useParams();
  const appRolesCtx = useContext(AppRolesContext);

  const getSubmittedDate = (request: any) => {
    const historyItem = request.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Submitted);
    return historyItem ? new Date(historyItem.updatedWhen * 1000).toLocaleDateString("en-GB") : null;
  }

  return (
    <tr>
      <Th scope="row">{data.title}</Th>
      <Td>{data.id}</Td>
      <Td>{data.history.length === 0 || data.status === AirlockRequestStatus.Draft || data.status === AirlockRequestStatus.Cancelled ? "-" : getSubmittedDate(data)}</Td>
      <Td>{data.reviews.length > 0 ? new Date(data.reviews[0].dateCreated * 1000).toLocaleDateString("en-GB") : "-"}</Td>
      <Td><GetStatusTag status={data.status} /></Td>
      <Td>{data.status === AirlockRequestStatus.Approved && data.files.length > 0 ? <Download request={data} isOnline={isOnline} /> : ""}</Td>
      <Td>
        {data.status === AirlockRequestStatus.Draft ? (
          <Link
            to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-submit/${data.id}`}}
            state={{ stateObj: data }}
          >
            Continue
          </Link>
          ) : (
            <>
              {data.status === AirlockRequestStatus.InReview ? (
                <>
                  {isResearcher || appRolesCtx.OId === data.createdBy.id ? (
                    <Link to={`/workspaces/${id}/airlocks/airlock-request-audit/${data.id}`}>View</Link>
                  ) : (
                    <Link to={`/workspaces/${id}/airlocks/airlockreviewrequest/${data.id}`} state={{ stateObj: data }}>Review</Link>
                  )}
                </>
              ) : (
                <Link to={`/workspaces/${id}/airlocks/airlock-request-audit/${data.id}`}>View</Link>
              )}
            </>
          )
        }
      </Td>
    </tr>
  )
}

const ExportDataRow = ({ isOnline, data, isResearcher }: any) => {
  const { id } = useParams();
  const appRolesCtx = useContext(AppRolesContext);

  const getSubmittedDate = (request: any) => {
    const historyItem = request.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Draft);
    return new Date(historyItem.updatedWhen * 1000).toLocaleDateString("en-GB");
  }

  return (
    <tr>
      <Th scope="row">{data.title}</Th>
      <Td>{data.id}</Td>
      <Td>{data.history.length === 0 || data.status === AirlockRequestStatus.Draft || data.status === AirlockRequestStatus.Cancelled ? "-" : getSubmittedDate(data)}</Td>
      <Td>{data.reviews.length > 0 ? new Date(data.reviews[0].dateCreated * 1000).toLocaleDateString("en-GB") : null}</Td>
      <Td><GetStatusTag status={data.status} /></Td>
      <Td>{data.status === AirlockRequestStatus.Approved && data.files.length > 0 ? <Download request={data} isOnline={isOnline} /> : ""}</Td>
      <Td>
        {data.status === AirlockRequestStatus.Draft ? (
          <>
            {/* Send to export triage stage one */}
            {(data.triageStatements.length === 0) && (
              <Link
                to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-triage-stage-1/${data.id}`}}
                state={{ stateObj: data }}
              >
                Continue
              </Link>
            )}
            {/* Send to export triage stage two */}
            {(data.triageStatements.length > 0 && data.statisticsStatements.length === 0) && (
              <Link
                to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-triage-stage-2/${data.id}`}}
                state={{ stateObj: data }}
              >
                Continue
              </Link>
            )}
            {/* Send to exemption page or submit depending on if they've completed the form or not */}
            {(data.triageStatements.length > 0 &&
              data.statisticsStatements.length > 0 &&
              data.triageLevel === AirlockTriage.Exemption) && (
                (data.contactTeamForm.length > 0) ? (
                  <Link
                    to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-submit/${data.id}`}}
                    state={{ stateObj: data }}
                  >
                    Continue
                  </Link>
                ) : (
                  <Link
                    to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-triage-exemption/${data.id}`}}
                    state={{ stateObj: data }}
                  >
                    Continue
                  </Link>
                )
              )}
              {/* Send to submit page */}
              {(data.triageStatements.length > 0 &&
                data.statisticsStatements.length > 0 &&
                data.triageLevel !== AirlockTriage.Exemption) && (
                  <Link
                    to={{ pathname: `/workspaces/${id}/airlocks/airlock-${data.type}-submit/${data.id}`}}
                    state={{ stateObj: data }}
                  >
                    Continue
                  </Link>
                )}
          </>
        ) : (
          <>
            {data.status === AirlockRequestStatus.InReview ? (
              <>
                {isResearcher || appRolesCtx.OId === data.createdBy.id ? (
                  <Link to={`/workspaces/${id}/airlocks/airlock-request-audit/${data.id}`}>View</Link>
                ) : (
                  <Link to={`/workspaces/${id}/airlocks/airlockreviewrequest/${data.id}`} state={{ stateObj: data }}>Review</Link>
                )}
              </>
            ) : (
              <Link to={`/workspaces/${id}/airlocks/airlock-request-audit/${data.id}`}>View</Link>
            )}
          </>
        )}
      </Td>
    </tr>
  )
}

const Download = ({ request, isOnline }: any) => {
  const [filesLinkError, setFilesLinkError] = useState(false);

  const workspaceCtx = useContext(WorkspaceContext);
  const apiCall = useAuthApiCall();
  const { id } = useParams();

  const generateFilesLink = useCallback(async (requestId: string, requestTitle: string, fileName: string) => {
    try {
      let airlockSaSTokenurl = `${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}/${requestId}/${ApiEndpoint.AirlockLink}`;
      console.log("sas token for", requestTitle, airlockSaSTokenurl);
      const linkObject = (await apiCall(airlockSaSTokenurl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));
      console.log("containerUrl: ", linkObject.containerUrl);
      console.log("containerUrl replace: ", linkObject.containerUrl.replace(requestId, `${requestId}/${fileName}`));
      const url = linkObject.containerUrl.replace(requestId, `${requestId}/${fileName}`);
      window.open(url, '_blank');
    } catch (err: any) {
      console.log("err: ", err);
      setFilesLinkError(true);
    }
  }, [apiCall]);

  return (
    <>
      {request.type === "import" ? (
        !isOnline && (
          <>
            <TextButton onClick={() => generateFilesLink(request.id, request.title, request.files[0].name)}>Download</TextButton>
            {filesLinkError && "error downloading link"}
          </>
        )
      ) : (
        isOnline && (
          <>
            <TextButton onClick={() => generateFilesLink(request.id, request.title, request.files[0].name)}>Download</TextButton>
            {filesLinkError && "error downloading link"}
          </>
        )
      )}
    </>
  )
}
